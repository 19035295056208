import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { translateToArabic } from "../localize/englishToArabic";
export const transactionsCols: GridColDef[] = [
    {
        field: "phone",
        headerName: "هاتف المستخدم",
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.user?.phone ?? "لا يوجد",
    },
    {
        field: "amout",
        headerName: "قيمة الشحن",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.amount ?? "لا يوجد",
    },
    {
        field: "type",
        headerName: "نوع العملية",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.type ? translateToArabic(params?.row?.type) : "لا يوجد",
    },
    {
        field: "isConfirmed",
        headerName: "موكدة",
        width: 60,
        sortable: false,
        filterable: false,
        valueGetter: (params) => (params.row?.is_confirmed ? "✅" : "❌"),
    },
    {
        field: "created_at",
        headerName: "التاريخ",
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params?.row?.created_at).format("YYYY/MM/DD-hh:mm")
                : "لا يوجد",
    },
    {
        field: "payment_method",
        headerName: "طريقة الدفع",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.payment_method ?? "غير معروف",
    },
    {
        field: "notes",
        headerName: "ملاحظات",
        width: 300,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.notes ?? "لا يوجد",
    },
];
