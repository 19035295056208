import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// react rouoter
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, Divider, TextField, Box } from "@mui/material";
// components
import {
    SideTitle,
    PageTitle,
    ThreeDotsLoader,
    OrderDetailsSection,
    ClientDetailsSection,
    DeliveryDetailsSection,
    RateDetailsSection,
    OrderItemsSection,
} from "../../components";
// types
import { ReduxUserReducerState } from "../../types/global";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// moment for time format
import moment from "moment";
// localize
import { translateToArabic } from "../../utils/localize/englishToArabic";
// utils
import { getRoleNameById } from "../../utils/roles/appRoles";

type Order = {
    friendly_id?: string;
    status?: string;
    total_items_price?: number;
    delivery_cost?: number;
    total_discount_value?: number;
    total_invoice_cost?: number;
    details?: string;
    created_at?: Date;
    payment_method?: { name?: string };
    customer?: {
        name?: string;
        phone?: number | string | null;
        email?: string;
        invoice_reference?: string;
        last_invoice?: number;
    };
    address?: {
        details?: string;
        area?: {
            name?: string;
        };
        city?: {
            name?: string;
        };
    };
    review?: {
        rate: number | string | null;
        comment: string | null;
    };
};
const ShowOrder = () => {
    const { orderId } = useParams();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);

    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState<Order>({});
    // get user details
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/${userRole}/invoices/${orderId}`)
            .then((res: AxiosResponse) => {
                setOrderDetails(res.data);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, []);
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack
            sx={{
                margin: "auto !important",
                width: "100%",
                maxWidth: "900px",
                gap: "10px",
                alignItems: "center",
                "& .MuiFormControl-root": {
                    minWidth: "290px",
                    width: "293.3px",
                },
            }}
        >
            <PageTitle title="تفاصيل الطلب" />
            <OrderDetailsSection orderDetails={orderDetails} />
            <Divider />
            <OrderItemsSection orderDetails={orderDetails} />
            <Divider />
            <ClientDetailsSection orderDetails={orderDetails} />
            <Divider />
            <DeliveryDetailsSection
                orderDetails={orderDetails}
                // styles={styles}
            />
            <Divider />
            <RateDetailsSection orderDetails={orderDetails} />
        </Stack>
    );
};

export default ShowOrder;
