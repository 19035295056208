import React, { ChangeEvent, useState } from "react";
// react redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// mui
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { ReduxUserReducerState } from "../../types/global";
// utils
import { getRoleNameById } from "../../utils/roles/appRoles";
// assets
type Status = {
    label: string;
    value: string;
};
const validStatus: Status[] = [
    {
        label: "ملغية",
        value: "cancelled",
    },
    {
        label: "قيد الانتظار",
        value: "pending",
    },
    {
        label: "قيد التجهيز",
        value: "preparing",
    },
    {
        label: "تم التوصيل",
        value: "completed",
    },
];
const ChangeOrderStatus = ({
    id,
    defaultValue,
}: {
    id: string;
    defaultValue: string;
}) => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);

    const [value, setValue] = useState(defaultValue);
    const handleChange = (e: SelectChangeEvent<string>) => {
        setValue(e.target.value);
        EnhancedAxios(null, userToken)
            .put(`/${userRole}/invoices/${id}`, {
                status: e.target.value,
            })
            .then((res: AxiosResponse) => {
                successAlert("تم تعديل حالة الطلب");
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    return (
        <FormControl fullWidth>
            <Select
                value={value}
                onChange={handleChange}
                sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "0",
                    },
                }}
            >
                {validStatus.map((status: Status) => {
                    return (
                        <MenuItem value={status.value} key={status.value}>
                            {status.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default ChangeOrderStatus;
