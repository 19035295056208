import * as yup from "yup";

export const updateCurrentUserPasswordSchema = yup.object().shape({
    password: yup
        .string()
        .required("كلمة المرور مطلوية ")
        .min(8, "كلمة المرور قصيرة"),

    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "لا يوجد تطابق لكلمة المرور")
        .required("يجب تاكيد كلمة المرور"),
});
