import { useState } from "react";
// redux
import { useSelector } from "react-redux";
// mui
import { Stack } from "@mui/material";
// componetns
import NavbarLink from "./NavbarLink";
// links
import { adminLinks } from "../../utils/navbar/navbarLinks";
import { operatorNavbarLinks } from "../../utils/navbar/operatorNavbarLinks";
// appRoles
import { appRoles } from "../../utils/roles/appRoles";
// types
import { ReduxUserReducerState } from "../../types/global";

const NavbarLinks = () => {
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const [links, setLinks] = useState(
        userData.roles.includes(appRoles.admin)
            ? adminLinks
            : userData.roles.includes(appRoles.operator)
            ? operatorNavbarLinks
            : []
    );
    return (
        <Stack width="100%">
            {links.map((lnk: any) => (
                <NavbarLink
                    key={lnk.path}
                    path={lnk.path}
                    title={lnk.title}
                    icon={lnk.icon}
                />
            ))}
        </Stack>
    );
};

export default NavbarLinks;
