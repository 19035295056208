import React, { useState } from "react";
// mui
import { Stack, Typography, Avatar } from "@mui/material";
import AnisSubCategoriesModal from "../modal/AnisSubCategoriesModal";
type Prop = {
    id: string;
    name: string;
    logo: string;
    subCategories?: {
        id: string;
        name: string;
        description: string;
        logo: string;
        inStock: boolean;
        hasSpecialOffer: boolean;
    }[];
};
const AnisProductCard = ({ id, name, logo, subCategories }: Prop) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const handleClose = () => setOpenModal(false);
    return (
        <>
            <AnisSubCategoriesModal
                parentCategoryName={name}
                open={openModal}
                handleClose={handleClose}
                subCategories={subCategories}
            />
            <Stack
                sx={{
                    alignItems: "center",
                    boxShadow: 1,
                    width: "160px",
                    height: "160px",
                    padding: "10px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    overflow: "hidden",
                    background: "#fdfdfd",
                    border: "none",
                    outline: "none",
                }}
                component="button"
                onClick={() => setOpenModal(true)}
            >
                <img
                    src={logo}
                    alt={name}
                    loading="lazy"
                    width="120"
                    height="120"
                />
                <Typography
                    variant="body2"
                    sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                    }}
                >
                    {name}
                </Typography>
            </Stack>
        </>
    );
};

export default AnisProductCard;
