// mui
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

type Prop = {
    id: string;
    name: string;
    logo: string;
};

const AnisSubCategoryCard = ({ id, logo, name }: Prop) => {
    return (
        <Stack
            sx={{
                alignItems: "center",
                boxShadow: 1,
                width: "160px",
                height: "160px",
                padding: "10px",
                borderRadius: "8px",
                cursor: "pointer",
                overflow: "hidden",
                background: "#fdfdfd",
            }}
            component={Link}
            to={`/connect-product-with-anis/${id}`}
        >
            <img
                src={logo}
                alt={name}
                loading="lazy"
                width="120"
                height="120"
            />
            <Typography
                variant="body2"
                sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                }}
            >
                {name}
            </Typography>
        </Stack>
    );
};

export default AnisSubCategoryCard;
