import { createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
// palette for light mode
import { lightPalette } from "./lightPalette";
// mui theme
export const RtlTheme = createTheme({
    direction: "rtl",
    typography: {
        fontFamily: `"Cairo", "Helvetica", "Arial", sans-serif`,
    },
    palette: { ...lightPalette },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.main",
                    },
                },
            },
        },
    },
});
// Create rtl cache
export const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
});
