import React, { useState } from "react";
// redux
import { useSelector } from "react-redux";
// react-hook-form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Box, Stack } from "@mui/material";
// componennts
import {
    PageTitle,
    SubmitButton,
    InputField,
    LinkButton,
} from "../../components";
// schema
import { pushNotificationSchema } from "../../schemes/notification/pushNotificationSchema";
import { ReduxUserReducerState } from "../../types/global";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// form values type
type FormValues = {
    topic: string;
    title: string;
    body: string;
};

const Notification = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            topic: "all",
            title: "",
            body: "",
        },
        resolver: yupResolver(pushNotificationSchema),
        mode: "all",
    });
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/fcm-notifications?lang=en`, data)
            .then((res: AxiosResponse) => {
                setSubmitting(false);
                successAlert("تم ارسال الاشعار بنجاح");
                reset();
            })
            .catch((error: AxiosError) => {
                setSubmitting(false);
                errorAlert(error);
            });
    };
    return (
        <Stack gap="20px">
            <PageTitle title="ارسال اشعار" />
            <Stack alignItems="center">
                <Box
                    width="100%"
                    maxWidth="800px"
                    marginBottom="20px"
                    display="flex"
                    justifyContent="flex-start"
                >
                    <LinkButton
                        to="/all-notifications"
                        text="جميع الاشعارات المرسلة"
                        variant="outlined"
                    />
                </Box>
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="text"
                        registration={{ ...register("title") }}
                        label="العنوان"
                        errorMessage={errors?.title?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("body") }}
                        label="المحتوى"
                        errorMessage={errors?.body?.message}
                        multiline={true}
                        minRows={3}
                        maxRows={6}
                    />
                    <SubmitButton label="ارسال" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Notification;
