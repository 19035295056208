import React, { FC } from "react";
// react router
import { NavLink } from "react-router-dom";
// mui
import { Box, Typography } from "@mui/material";

type Props = {
    icon: any;
    title: string;
    path: string;
};

const NavbarLink = ({ title, path, icon }: Props) => {
    return (
        <Box
            component={NavLink}
            to={path}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="10px"
            sx={{
                color: "inherit",
                padding: "8px",
                borderRadius: "5px",
                transition: ".3s ease",
                "&:hover": {
                    backgroundColor: "primary.light",
                },
                "&.active": {
                    backgroundColor: "primary.light",
                },
            }}
        >
            <Typography component="span" fontSize="18px">
                {React.createElement(icon)}
            </Typography>
            <Typography component="span" fontSize="16px">
                {title}
            </Typography>
        </Box>
    );
};

export default NavbarLink;
