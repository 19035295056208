import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type IntialState = {
    open: boolean;
    user: {
        name: string;
        id: string;
    };
};

const initialState: IntialState = {
    open: false,
    user: {
        name: "",
        id: "",
    },
};

export const notifySlice = createSlice({
    name: "notify",
    initialState,
    reducers: {
        setOpen: (state: IntialState, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setNotifyUserData: (state: IntialState, action: PayloadAction<any>) => {
            state.user.id = action.payload.id;
            state.user.name = action.payload.name;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setOpen, setNotifyUserData } = notifySlice.actions;

export default notifySlice.reducer;
