import React from "react";
// mui
import { Stack, TextField } from "@mui/material";
// components
import PageTitle from "../../title/PageTitle";
import SideTitle from "../../title/SideTitle";
// date format
import moment from "moment";
// translate
import { translateToArabic } from "../../../utils/localize/englishToArabic";
// styles
import styles from "./showOrder.module.css";
const OrderDetailsSection = ({ orderDetails }: any) => {
    return (
        <Stack spacing="10px">
            <SideTitle title="بيانات الطلب" />
            <Stack
                direction="row"
                gap="10px"
                alignItems="center"
                justifyContent="start"
                flexWrap="wrap"
            >
                <TextField
                    label="رقم الطلب"
                    value={orderDetails?.friendly_id ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="الحالة"
                    value={
                        orderDetails?.status
                            ? translateToArabic(orderDetails?.status)
                            : "لا يوجد"
                    }
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="اجمالى قيمة العناصر"
                    value={orderDetails?.total_items_price ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="قيمة التوصيل"
                    value={orderDetails?.delivery_cost ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="اجمالى قيمة الخصم"
                    value={orderDetails?.total_discount_value ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="اجمالى قيمة الطلب"
                    value={orderDetails?.total_invoice_cost ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                    label="طريقة الدفع"
                    value={orderDetails?.payment_method?.name ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="تاريخ طلب الطلب"
                    value={
                        orderDetails?.created_at
                            ? moment(orderDetails?.created_at).format(
                                  "YYYY/MM/DD HH:MM"
                              )
                            : "لا يوجد"
                    }
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Stack>

            <TextField
                label="التفاصيل"
                value={orderDetails?.details ?? "لا يوجد"}
                multiline={true}
                minRows={2}
                InputProps={{
                    readOnly: true,
                }}
                sx={{ width: "100% !important", flex: "1" }}
                className={styles.textarea_field}
            />
        </Stack>
    );
};

export default OrderDetailsSection;
