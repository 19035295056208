import React, { ChangeEvent, useState } from "react";
// mui
import { Switch } from "@mui/material";
// Components
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// react redux
import { useSelector } from "react-redux";
// alerts
import {
    errorAlert,
    successAlert,
    warningAlert,
} from "../../utils/alerts/alerts";
type Props = {
    is_verified: boolean;
    id: string;
};
const IsVerifiedSwitch = ({ is_verified, id }: Props) => {
    const userToken = useSelector((state: any) => state.user.userToken);
    const [isVerifiedValue, setIsVerifiedValue] = useState(is_verified);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!is_verified) {
            const formData: any = new FormData();
            formData.append("is_verified", "true");
            EnhancedAxios("multipart/form-data", userToken)
                .put(`/admin/users/${id}`, formData)
                .then((res: AxiosResponse) => {
                    successAlert("تم التحقق");
                    setIsVerifiedValue(true);
                })
                .catch((error: AxiosError) => {
                    errorAlert(error);
                });
        } else {
            warningAlert("تم التحقق من هذا الحساب بالفعل");
        }
    };
    return (
        <Switch
            size="small"
            color="success"
            checked={isVerifiedValue}
            onChange={handleChange}
        />
    );
};

export default IsVerifiedSwitch;
