import React, { FC, ReactElement } from "react";
// react router
import { Link } from "react-router-dom";
// mui
import { Button } from "@mui/material";
type Props = {
    text?: string;
    to: string;
    size?: "large" | "small";
    variant?: "contained" | "outlined";
    icon?: ReactElement | null;
};
const LinkButton = ({
    text = "اضافة",
    to = "/",
    size = "large",
    variant = "contained",
    icon = null,
}: Props) => {
    return (
        <Button
            size={size}
            variant={variant}
            color="primary"
            component={Link}
            to={to}
            endIcon={icon ?? icon}
            sx={{ width: "fit-content", boxShadow: "0" }}
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
        >
            {text}
        </Button>
    );
};

export default LinkButton;
