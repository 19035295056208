import React, { FC } from "react";
import { Typography } from "@mui/material";
type Props = {
    title: string;
};
const SideTitle = ({ title }: Props) => {
    return (
        <Typography variant="h5" fontWeight="500">
            {title}
        </Typography>
    );
};

export default SideTitle;
