import React from "react";
// mui
import { TextField } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";

type Props = {
    label: string;
    type: string;
    registration: UseFormRegisterReturn;
    errorMessage: string | undefined;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    defaultValue?: any;
    disabled?: boolean;
    styles?: any;
    readOnly?: boolean;
    step?: number;
};

const InputField = ({
    type,
    registration,
    label,
    errorMessage,
    multiline,
    minRows,
    maxRows,
    defaultValue,
    disabled = false,
    styles,
    readOnly = false,
    step = 0.01,
}: Props) => {
    return (
        <TextField
            type={type}
            label={label}
            {...registration}
            error={errorMessage ? true : false}
            helperText={errorMessage}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            defaultValue={defaultValue}
            disabled={disabled}
            sx={{ ...styles }}
            inputProps={{
                step: step,
                readOnly: readOnly,
            }}
        />
    );
};

export default InputField;
