import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// reacthook form
import {
    UseFormGetValues,
    UseFormRegisterReturn,
    UseFormSetValue,
    Controller,
} from "react-hook-form";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

type Props = {
    getValues: UseFormGetValues<any>;
    errorMessage?: string | undefined;
    setValue: UseFormSetValue<any>;
    control: any;
    name?: string;
};

const SelectCity = ({
    getValues,
    errorMessage,
    setValue,
    control,
    name = "city",
}: Props) => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    // // get alll cities
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/cities?&sorts=-name&page=1&paginate=120`)
            .then((res: AxiosResponse) => {
                const newCitiessForm = res.data.data.map((city: any) => {
                    const v = { id: city.id, name: city.name };
                    return v;
                });
                setCities(newCitiessForm);
                setLoading(false);
            })
            .catch((error: AxiosError) =>
                errorAlert(
                    "حدث خطا اثناء العرص التصنيفات برجاء اعادة تحميل الصفحة"
                )
            );
    }, []);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    sx={{ width: "100%" }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                    }
                    getOptionLabel={(option: any) => option?.name}
                    options={cities}
                    loading={loading}
                    {...field}
                    onChange={(e, item: any) => {
                        setValue(name, item);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="المدينة"
                            helperText={errorMessage}
                            error={errorMessage ? true : false}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <AiOutlineLoading3Quarters />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default SelectCity;
