import { TextField } from "@mui/material";
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useState,
} from "react";

type Props = {
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
};

const SearchBox = ({ searchValue, setSearchValue }: Props) => {
    const [value, setValue] = useState<string>(searchValue);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setTimeout(() => {
            setValueOfSearchBox(e.target.value);
        }, 1500);
    };
    const setValueOfSearchBox = useCallback(
        (value: string) => {
            setSearchValue(value);
        },
        [value]
    );
    return (
        <TextField
            name="search_box"
            id="search_box"
            label="بحث"
            value={value}
            onChange={handleChange}
            sx={{
                flex: "1",
                minWidth: "230px",
            }}
        />
    );
};

export default SearchBox;
