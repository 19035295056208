import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
// react redux
import { useSelector } from "react-redux";
// react-hook-form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
// mui
import { Stack, FormControlLabel, Switch } from "@mui/material";
// componennts
import {
    PageTitle,
    SubmitButton,
    InputField,
    ThreeDotsLoader,
} from "../../components";
// schema
import { editCitySchema } from "../../schemes/city/editCitySchema";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// form values type
type FormValues = {
    name: string;
    is_disabled: boolean;
};

const EditCity = () => {
    const navigate = useNavigate();
    const { cityId } = useParams();
    const userToken = useSelector((state: any) => state.user.userToken);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            name: "",
            is_disabled: false,
        },
        resolver: yupResolver(editCitySchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        getValues,
        reset,
        control,
        watch,
        formState: { errors },
        handleSubmit,
    } = form;
    // submit form
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .put(`/admin/cities/${cityId}`, data)
            .then((res: any) => {
                successAlert(`تم تعديل مدينة  '${data.name}'`);
                setSubmitting(false);
                navigate(-1);
            })
            .catch((error: any) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // get city data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/cities/${cityId}`)
            .then((res: any) => {
                reset({
                    name: res.data.name,
                    is_disabled: res.data.is_disabled,
                });
                setLoading(false);
            })
            .catch((error: any) => {
                errorAlert(error);
            });
    }, []);
    // while page is loading
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="تعديل مدينة" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="text"
                        registration={{ ...register("name") }}
                        label="الاسم"
                        errorMessage={errors?.name?.message}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="تعديل" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditCity;
