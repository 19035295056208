import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    SelectChangeEvent,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
type SortOptions = {
    sortTitle: string;
    sortKey: string;
};
const defaultSortingOptions: SortOptions[] = [
    {
        sortTitle: "الاسم تنازلياً",
        sortKey: "name",
    },
    {
        sortTitle: "الاسم تصاعدياً",
        sortKey: "-name",
    },
    {
        sortTitle: "تاريخ الانشاء من الاحدث الى الاقدم",
        sortKey: "-created_at",
    },
    {
        sortTitle: "تاريخ الانشاء من الاقدم الى الاحدث",
        sortKey: "created_at",
    },
    {
        sortTitle: "تاريخ التحديث من الاحدث الى الاقدم",
        sortKey: "-updated_at",
    },
    {
        sortTitle: "تاريخ التحديث من الاقدم الى الاحدث",
        sortKey: "updated_at",
    },
];

type Props = {
    sortValue: string;
    setSortValue: Dispatch<SetStateAction<string>>;
    sortingOptions?: SortOptions[];
};

const SortBox = ({
    sortValue,
    setSortValue,
    sortingOptions = defaultSortingOptions,
}: Props) => {
    const handleChange = (e: SelectChangeEvent<string>) => {
        setSortValue(e.target.value);
    };
    return (
        <Box sx={{ flex: "1", minWidth: "230px" }}>
            <FormControl fullWidth>
                <InputLabel>ترتيب حسب</InputLabel>
                <Select
                    value={sortValue}
                    label="ترتيب حسب"
                    onChange={handleChange}
                >
                    {sortingOptions.map((option: SortOptions) => (
                        <MenuItem value={option.sortKey} key={option.sortKey}>
                            {option.sortTitle}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SortBox;
