import React, { FC } from "react";
// mui
import { Button, Typography } from "@mui/material";

type Props = {
    label: string;
    disabled?: boolean;
    size?: "large" | "small";
};

const SubmitButton = ({ label, disabled = false, size = "large" }: Props) => {
    return (
        <Button
            size={size}
            color="primary"
            type="submit"
            disabled={disabled}
            variant="contained"
        >
            <Typography variant="body1">{label}</Typography>
        </Button>
    );
};

export default SubmitButton;
