import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
// mui
import {
    Stack,
    Box,
    Typography,
    Divider,
    Switch,
    Button,
    Tooltip,
} from "@mui/material";
// Components
import { LinkButton } from "../../components";
import EnhancedAxios from "../../axios/EnhancedAxios";
import { useSelector } from "react-redux";
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from "../../utils/alerts/alerts";
type Props = {
    label: string;
    secLabel?: string | undefined;
    id: String;
    endpointPath: string;
    editPagePath: string;
    is_disabled: boolean | any;
    setDataChanged: Dispatch<SetStateAction<boolean>>;
};

const LabelCard = ({
    label,
    secLabel = "",
    id,
    endpointPath,
    editPagePath,
    is_disabled,
    setDataChanged,
}: Props) => {
    const userToken = useSelector((state: any) => state.user.userToken);
    const [isActive, setIsActive] = useState(!is_disabled);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = !e.target.checked;
        const formData: any = new FormData();
        formData.append("is_disabled", v);
        EnhancedAxios(null, userToken)
            .put(endpointPath, formData)
            .then((res: any) => {
                const alertMsg =
                    res.data.is_disabled === true ? "تم التعطيل" : "تم التفعيل";
                successAlert(alertMsg);
                setIsActive(!res.data.is_disabled);
            });
    };
    return (
        <Stack
            minWidth="290px"
            width="300px"
            p={2}
            spacing={2}
            alignItems="center"
            sx={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                opacity: !isActive ? "0.7" : "1.0",
                "&:hover": {
                    boxShadow: 1,
                },
            }}
        >
            <Stack>
                <LabelOfLabelCard label={label} />
                <SecLabelOfLabelCard secLabel={secLabel} />
            </Stack>
            <Divider flexItem />
            <Box width="100%" display="flex" justifyContent="space-between">
                <Stack spacing={1} direction="row">
                    <LinkButton
                        to={editPagePath}
                        text="تعديل"
                        size="small"
                        variant="outlined"
                    />
                    {/* <DeleteButtonOfLabelCard
                        id={id}
                        userToken={userToken}
                        label={label}
                        setDataChanged={setDataChanged}
                        endpointPath={endpointPath}
                    /> */}
                </Stack>
                <Switch
                    size="small"
                    checked={isActive}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    );
};

export default LabelCard;

const LabelOfLabelCard: React.FC<{ label: string }> = ({ label }) => {
    return (
        <Tooltip title={label}>
            <Typography variant="h6" textAlign="center">
                {label.length > 16 ? `${label.slice(0, 16)}...` : label}
            </Typography>
        </Tooltip>
    );
};

const SecLabelOfLabelCard: React.FC<{ secLabel: string }> = ({ secLabel }) => {
    return secLabel ? (
        <Typography variant="body2" textAlign="center">
            {secLabel.length > 22 ? `${secLabel.slice(0, 22)}...` : secLabel}
        </Typography>
    ) : (
        <></>
    );
};
type DeleteProps = {
    id: String;
    userToken: string;
    label: String;
    setDataChanged: Dispatch<SetStateAction<boolean>>;
    endpointPath: string;
};
const DeleteButtonOfLabelCard = ({
    id,
    userToken,
    label,
    setDataChanged,
    endpointPath,
}: DeleteProps) => {
    const deleteProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(endpointPath)
            .then((res: any) => {
                successAlert(`تم حذف "${label}"`);
                setDataChanged((prev: Boolean) => !prev);
            })
            .catch((error: any) => {
                errorAlert(error);
            });
    };
    const confirmDelete = () => {
        askForConfirmationAlert(
            "هل انت متاكد انك تريد حذف هذا العنصر",
            deleteProcess
        );
    };
    return (
        <Button size="small" onClick={confirmDelete}>
            حذف
        </Button>
    );
};
