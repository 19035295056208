import { useState, useEffect, useRef, ChangeEvent } from "react";
import { SetFieldValue } from "react-hook-form";
// mui
import { Box, Avatar, Button, Typography } from "@mui/material";
// icons
import { FiUploadCloud } from "react-icons/fi";
import { CiImageOn } from "react-icons/ci";
type Props = {
    defaultImage?: string;
    IPWidth?: string;
    IPHeight?: string;
    IPVariant?: "rounded" | "square" | "circular";
    IPObjectFit?: string;
    resetPreview?: boolean;
    withoutUploadBtn?: boolean;
    setValue?: SetFieldValue<any>;
    errorMessage?: string | undefined;
};
const ImagePreview = ({
    defaultImage,
    setValue,
    IPWidth,
    IPHeight = "200px",
    IPVariant = "rounded",
    IPObjectFit = "cover",
    resetPreview = false,
    withoutUploadBtn = false,
    errorMessage,
}: Props) => {
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imgSrc, setImgSrc] = useState<string | undefined>(
        defaultImage ? defaultImage : ""
    );
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    // --------------------------------------- handle file change to pass file to preview component ------------------------------
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            let x = e.target.files[0];
            if (setImageFile) {
                setImageFile(x);
                setValue("img", x);
            }
        }
    };
    // --------------------------------------- show file in preview ---------------------------------------
    const showFileInPreview = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const finalResult: string | undefined = reader.result?.toString();
            setImgSrc(finalResult?.toString());
        };
    };
    const customButtonClicked = () => {
        if (inputFileRef) inputFileRef.current?.click();
    };
    // detect when file change
    useEffect(() => {
        if (imageFile) {
            showFileInPreview(imageFile);
        }
    }, [imageFile]);
    // reset preview
    useEffect(() => {
        if (resetPreview) {
            setImgSrc("");
        }
    }, [resetPreview]);
    // componetn ui
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
        >
            {/* default button we hidden  */}
            <input
                type="file"
                onChange={handleFileChange}
                ref={inputFileRef}
                style={{ display: "none" }}
                accept="image/png, image/gif, image/jpeg"
            />
            <Avatar
                src={imgSrc}
                alt="image preview"
                variant={IPVariant}
                sx={{
                    boxShadow: 1,
                    width: IPWidth ? IPWidth : { xs: "290px", sm: "450px" },
                    height: `${IPHeight}`,
                    "& .MuiAvatar-img": {
                        objectFit: IPObjectFit,
                    },
                }}
            >
                <p style={{ fontSize: "80px" }}>
                    <CiImageOn />
                </p>
            </Avatar>
            {errorMessage ? (
                <Typography
                    variant="body2"
                    textAlign="center"
                    color="error.main"
                    fontSize="12px"
                >
                    {errorMessage}
                </Typography>
            ) : (
                <></>
            )}
            {/* custom file explore we create  */}
            {!withoutUploadBtn && (
                <Box display="flex" justifyContent="center" width="100%">
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: "basicColor.white" }}
                        endIcon={<FiUploadCloud />}
                        onClick={customButtonClicked}
                    >
                        تحميل صورة
                    </Button>
                </Box>
            )}
            {/* upload button  */}
        </Box>
    );
};

export default ImagePreview;
