import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReduxUserIntialState } from "../../../types/global";

const initialState: ReduxUserIntialState = {
    userToken: localStorage.getItem("zonestore_user_token") ?? "",
    userData: localStorage.getItem("zonestore_user_data")
        ? JSON.parse(localStorage.getItem("zonestore_user_data") ?? "")
        : null,
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserToken: (state, action: PayloadAction<string>) => {
            state.userToken = action.payload;
            localStorage.setItem(
                "zonestore_user_token",
                action.payload.toString()
            );
        },
        removeUserToken: (state) => {
            state.userToken = "";
            localStorage.removeItem("zonestore_user_token");
        },
        setUserData: (state, action: PayloadAction<any>) => {
            state.userData = action.payload;
            localStorage.setItem(
                "zonestore_user_data",
                JSON.stringify(action.payload)
            );
        },
        removeUserData: (state, action: PayloadAction<any>) => {
            state.userData = {};
            localStorage.removeItem("zonestore_user_data");
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUserToken, removeUserToken, setUserData, removeUserData } =
    userSlice.actions;

export default userSlice.reducer;
