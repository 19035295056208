import { useState, FC } from "react";
// react router
import { Outlet } from "react-router";
// mui
import { Box, IconButton, Stack, Container } from "@mui/material";
// components
import { Navbar } from "../../components";
// icons
import { FaAngleDoubleLeft } from "react-icons/fa";

const AdminLayout = () => {
    const [navbarOpen, setNavbarOpen] = useState<boolean>(true);
    return (
        <Box display="flex">
            <Navbar navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
            <Box flex="1" height="100vh" sx={{ overflow: "auto" }}>
                <Container>
                    <Stack spacing="10px" py="40px">
                        <IconButton
                            onClick={() => setNavbarOpen(true)}
                            sx={{
                                width: "50px",
                                height: "50px",
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            <FaAngleDoubleLeft />
                        </IconButton>
                        <Outlet />
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default AdminLayout;
