import React, { useState, useEffect } from "react";
// mui
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
// react-hook-form
import { Controller } from "react-hook-form";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { errorAlert } from "../../utils/alerts/alerts";
import { useSelector } from "react-redux";
type Props = {
    control: any;
    defaultValue?: any;
};
const SelectUnit = ({ control, defaultValue }: Props) => {
    const userToken = useSelector((state: any) => state.user.userToken);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/units?sorts=-name&page=1&paginate=120`)
            .then((res: ResponseType | any) => {
                setOptions(res?.data?.data);
                setLoading(false);
            })
            .catch((error: any) => {
                errorAlert(error);
            });
    }, []);
    return (
        <Controller
            control={control}
            name="unit"
            render={({ field }) => (
                <FormControl fullWidth>
                    <InputLabel>الوحدة</InputLabel>
                    <Select label="الوحدة" {...field}>
                        {loading ? (
                            <MenuItem disabled value="">
                                تحميل...
                            </MenuItem>
                        ) : (
                            options.map((opt: any) => (
                                <MenuItem
                                    key={opt.id}
                                    value={JSON.stringify({ id: opt.id })}
                                >
                                    {opt?.name}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            )}
        />
    );
};

export default SelectUnit;
