import { Stack, TextField } from "@mui/material";
import React from "react";
import SideTitle from "../../title/SideTitle";

const DeliveryDetailsSection = ({ orderDetails }: any) => {
    return (
        <Stack gap="10px">
            <SideTitle title="بيانات التوصيل" />
            <Stack
                direction="row"
                gap="10px"
                alignItems="center"
                flexWrap="wrap"
            >
                <TextField
                    label="تفاصيل العنوان"
                    value={orderDetails?.address?.details ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="المنطقة"
                    value={orderDetails?.address?.area?.name ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="المدينة"
                    value={orderDetails?.address?.city?.name ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default DeliveryDetailsSection;
