import { PaletteOptions } from "@mui/material";
interface CustomPaletteOptions extends PaletteOptions {
    textColor: {
        main: string;
        secondary: string;
        xmain: string;
    };
    basicColor: {
        white: "#fff";
        black: "#000";
    };
}

export const lightPalette: CustomPaletteOptions = {
    primary: {
        main: "#196977",
    },
    secondary: {
        main: "#0D5437",
    },
    textColor: {
        main: "#000",
        secondary: "#999",
        xmain: "#fff",
    },
    basicColor: {
        white: "#fff",
        black: "#000",
    },
};
