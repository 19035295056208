import React, { useState } from "react";
import { useParams, useNavigate } from "react-router";
// react redux
import { useSelector } from "react-redux";
// react-hook-form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, FormControlLabel, Checkbox, Switch } from "@mui/material";
// componennts
import {
    PageTitle,
    SubmitButton,
    InputField,
    ImagePreview,
} from "../../components";
// schema
import { addCategorySchema } from "../../schemes/category/addCategorySchema";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";
// form values type
type FormValues = {
    img?: File;
    name: string;
    is_disabled: boolean;
    parent: {
        id: String | undefined;
    };
};

const AddCategory = () => {
    const navigate = useNavigate();
    const { parentId } = useParams();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [resetPreview, setResetPreview] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            img: undefined,
            name: "",
            is_disabled: false,
            parent: { id: parentId },
        },
        resolver: yupResolver(addCategorySchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        reset,
        watch,
        control,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        const formData: any = new FormData();
        for (let key in data) {
            if (key === "parent") {
                formData.append(key, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/categories`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم اضافة تصنيف '${data.name}'`);
                setResetPreview(true);
                reset();
                setSubmitting(false);
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة تصنيف" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message}
                        IPWidth="200px"
                        resetPreview={resetPreview}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("name") }}
                        label="الاسم"
                        errorMessage={errors?.name?.message}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="انشاء" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddCategory;
