import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
// mui
import { Avatar, Stack, Box, Button, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import EnhancedAxios from "../../axios/EnhancedAxios";
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from "../../utils/alerts/alerts";
import { useSelector } from "react-redux";
type Props = {
    id: number;
    img: string;
    checked: boolean;
    setDataChanged: Dispatch<SetStateAction<boolean>>;
};
const BannerCard = ({ id, img, checked, setDataChanged }: Props) => {
    const userToken = useSelector((state: any) => state.user.userToken);
    const [disabledValue, setDisabledValue] = useState(!checked);
    // change status
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = !e.target.checked;
        const formData: any = new FormData();
        formData.append("is_disabled", v);
        EnhancedAxios(null, userToken)
            .put(`/admin/banners/${id}`, formData)
            .then((res: any) => {
                const alertMsg =
                    res.data.is_disabled === true ? "تم التعطيل" : "تم التفعيل";
                successAlert(alertMsg);
                setDisabledValue(!res.data.is_disabled);
            });
    };
    // delete banner
    const deleteProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(`/admin/banners/${id}`)
            .then((res: any) => {
                successAlert(`تم حذف البانر`);
                setDataChanged((prev: Boolean) => !prev);
            })
            .catch((error: any) => {
                errorAlert(error);
            });
    };
    const confirmDelete = () => {
        askForConfirmationAlert(
            "هل انت متاكد انك تريد حذف هذا العنصر",
            deleteProcess
        );
    };
    return (
        <Stack
            sx={{
                // flex: "1",
                minWidth: { xs: "290px", sm: "400px" },
                border: "1px solid #ddd",
                boxShadow: 1,
                opacity: checked ? "0.7" : "1",
                borderRadius: "5px",
            }}
        >
            <Box borderBottom="1px solid #ddd">
                <Avatar
                    src={img}
                    variant="rounded"
                    sx={{
                        width: "100%",
                        height: "150px",
                    }}
                />
            </Box>
            <Box sx={{ padding: "10px" }}>
                <Button
                    variant="outlined"
                    size="small"
                    component={Link}
                    to={`/edit-banner/${id}`}
                >
                    تعديل
                </Button>
                <Button size="small" onClick={confirmDelete}>
                    حذف
                </Button>
                <Switch
                    size="small"
                    checked={disabledValue}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    );
};

export default BannerCard;
