import React, { Component, useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// react hook form  and validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Box } from "@mui/material";
// Component
import InputField from "./InputField";
import SubmitButton from "../button/SubmitButton";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

type Props = {
    fieldKey: string;
    id: number;
    title: string;
    type: string;
    value: string;
    schema: any;
};
const SettingFieldForm = ({
    fieldKey,
    id,
    title,
    type,
    value,
    schema,
}: Props) => {
    // state
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            value: value,
        },
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });
    // submit form
    const onSubmit = (data: any) => {
        const formData = new FormData();
        formData.append("value", data.value);
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/settings/${id}`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم تعديل ${title}`);
                setSubmitting(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <InputField
                label={title}
                errorMessage={errors?.value?.message?.toString()}
                registration={{ ...register("value") }}
                type={type}
                styles={{ flex: "1" }}
            />
            <SubmitButton label="تعديل" disabled={submitting} />
        </Box>
    );
};

export default SettingFieldForm;
