import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import usePageParams from "../../hooks/usePageParams";
// mui
import { Stack, Box } from "@mui/material";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// components
import {
    PageTitle,
    SortBox,
    SearchBox,
    PaginationFilter,
    ThreeDotsLoader,
    NotificationCard,
} from "../../components";
// icons
import { MdAdd } from "react-icons/md";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

const AllNotifications = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const { page, setPage } = usePageParams();
    const [sortValue, setSortValue] = useState<string>("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalItems, setTotalItems] = useState(24);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    // get all areas
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/fcm-notifications?page=${page}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}`
            )
            .then((res: AxiosResponse) => {
                setNotifications(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, [page, itemsPerPage, sortValue, searchValue]);
    return (
        <Stack gap="20px">
            <PageTitle title="الاشعارات المرسلة" />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <ThreeDotsLoader />
                ) : notifications.length === 0 ? (
                    <p className="center-text">لا توجد عناصر لعرضها</p>
                ) : (
                    notifications.map((notificationDetails) => (
                        <NotificationCard
                            notificationDetails={notificationDetails}
                        />
                    ))
                )}
            </Box>
            {loading ? (
                <></>
            ) : (
                <PaginationFilter
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default AllNotifications;
