import React, { ChangeEvent } from "react";
// mui
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
} from "@mui/material";
// hook form
import { Controller } from "react-hook-form";
type Props = {
    errorMessage: string | undefined;
    control: any;
};

const SelectGender = ({ errorMessage, control }: Props) => {
    return (
        <FormControl fullWidth error={errorMessage ? true : false}>
            <InputLabel>النوع</InputLabel>
            <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                    <Select label="النوع" {...field}>
                        {genders.map((gender, index) => (
                            <MenuItem key={index} value={gender.value}>
                                {gender.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {errorMessage ? (
                <FormHelperText id="gender-error-msg">
                    {errorMessage}
                </FormHelperText>
            ) : (
                <></>
            )}
        </FormControl>
    );
};

export default SelectGender;
const genders = [
    {
        label: "ذكر",
        value: "male",
    },
    {
        label: "أنثى",
        value: "female",
    },
];
