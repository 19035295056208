import { IconType } from "react-icons";
import { MdOutlineForwardToInbox } from "react-icons/md";
import { BsBox2 } from "react-icons/bs";

interface Link {
    title: string;
    path: string;
    icon?: IconType | null;
}

export const operatorNavbarLinks: Link[] = [
    {
        title: "ادارة الطلبات",
        path: "/orders",
        icon: MdOutlineForwardToInbox,
    },
    {
        title: "ادارة المنتجات",
        path: "/products",
        icon: BsBox2,
    },
    {
        title: "ادارة منتجات أنيس",
        path: "/anis-products",
        icon: BsBox2,
    },
];
