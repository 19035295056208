import * as yup from "yup";

export const editOperatorSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, "اسم المشغل يجب ان يكون اكبر من 3 حروف")
        .required("ادخل اسم الشمغل المراد انشاؤه"),
    email: yup
        .string()
        .email("ادحل بريد الكتروني صيحيح")
        .required("البريد الكتروني مطلوب"),
    password: yup
        .string()
        .test(
            "length-test",
            "الرقم السري يجب ان يكون اكبر من ٧ حروف",
            (value) => value.length >= 8 || value === ""
        ),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "تأكد من تطابق الرقم السري"),
});
