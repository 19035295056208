// router
import { useParams } from "react-router";
// mui
import { Stack, Divider, Button } from "@mui/material";
// components
import {
    PageTitle,
    EditUserForm,
    ChangeUserBalanceModal,
} from "../../components";
import { useState } from "react";

const EditUser = () => {
    const { userId } = useParams<string>();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    return (
        <Stack gap="20px">
            <PageTitle title="تعديل المستخدم" />
            <Stack alignItems="center" spacing={1}>
                <EditUserForm />
                <Button onClick={() => setOpen(true)}>
                    تعديل رصيد المستخدم
                </Button>
                <ChangeUserBalanceModal open={open} handleClose={handleClose} />
            </Stack>
        </Stack>
    );
};

export default EditUser;
