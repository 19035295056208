// sweet alert
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content';
// const MySwal = withReactContent(Swal);

// ask for confirmation
export const askForConfirmationAlert = (text: string, fn: any) => {
    Swal.fire({
        text: text,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#196977",
    }).then((result: any) => {
        if (result.isConfirmed) {
            fn();
        }
    });
};
// success oprations
export const successAlert = (text = "لا يوجد رسالة") => {
    Swal.fire({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        text: text,
        icon: "success",
    });
};
// warning alert message
export const warningAlert = (text = "لا يوجد رسالة") => {
    Swal.fire({
        text: text,
        icon: "warning",
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: "top",
        showConfirmButton: false,
    });
};
// error alert message
export const errorAlert = (error: any) => {
    Swal.fire({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        text: error.response?.data?.message || error.message || "حدث خطأ",
        icon: "error",
    });
};
