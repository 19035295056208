import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import usePageParams from "../../hooks/usePageParams";
// mui
import { Stack, Box } from "@mui/material";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// components
import {
    PageTitle,
    SortBox,
    SearchBox,
    PaginationFilter,
    LinkButton,
    BannerCard,
    StatusFilterBox,
    ThreeDotsLoader,
} from "../../components";
// icons
import { MdAdd } from "react-icons/md";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

const Banners = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const { page, setPage } = usePageParams();
    const [sortValue, setSortValue] = useState<string>("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalItems, setTotalItems] = useState(24);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);
    const [filterValues, setFilterValues] = useState({
        status: "",
    });
    // get all areas
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/banners?sorts=${sortValue}&page=${page}&paginate=${itemsPerPage}&filters=${filterValues.status}`
            )
            .then((res: AxiosResponse) => {
                setBanners(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, [page, itemsPerPage, sortValue, dataChanged, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="ادراة التصنيفات" />
            <Stack>
                <LinkButton
                    text="اضافة بانر"
                    to="/add-banner"
                    icon={<MdAdd />}
                />
            </Stack>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
                <StatusFilterBox
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexWrap="wrap"
            >
                {loading ? (
                    <ThreeDotsLoader />
                ) : banners.length === 0 ? (
                    <p>لا توجد عناصر لعرضها</p>
                ) : (
                    banners.map((banner: any) => (
                        <BannerCard
                            id={banner.id}
                            img={banner.img}
                            checked={banner.is_disabled}
                            setDataChanged={setDataChanged}
                        />
                    ))
                )}
            </Box>
            <PaginationFilter
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={page}
                setCurrentPage={setPage}
            />
        </Stack>
    );
};

export default Banners;
