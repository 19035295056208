import React from "react";
// mui
import { Stack, Box, Typography } from "@mui/material";
// icons
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineMessage } from "react-icons/md";

type Props = {
    phone: string;
    content: string;
};
const OtpMessageCard = ({ phone, content }: Props) => {
    return (
        <Stack width="100%" sx={{ boxShadow: 1, p: 2, gap: "10px" }}>
            <Stack direction="row" gap="5px" alignItems="center">
                <Typography variant="h6">
                    <IoPhonePortraitOutline />
                </Typography>
                <Typography variant="subtitle1">{phone}</Typography>
            </Stack>
            <Stack direction="row" gap="5px" alignItems="center">
                <Typography variant="h6">
                    <MdOutlineMessage />
                </Typography>
                <Typography variant="subtitle1">{content}</Typography>
            </Stack>
        </Stack>
    );
};

export default OtpMessageCard;
