import React from "react";
// mui
import { Stack, TextField } from "@mui/material";
// components
import SideTitle from "../../title/SideTitle";

const ClientDetailsSection = ({ orderDetails }: any) => {
    return (
        <Stack spacing="10px">
            <SideTitle title="بيانات العميل" />
            <Stack
                direction="row"
                gap="10px"
                alignItems="center"
                flexWrap="wrap"
            >
                <TextField
                    label="الاسم"
                    value={orderDetails?.customer?.name ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="رقم الهاتف"
                    value={orderDetails?.customer?.phone ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="البريد الالكترونى"
                    value={orderDetails?.customer?.email ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default ClientDetailsSection;
