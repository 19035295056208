import { useEffect, useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Divider, Stack, Box, Tooltip, IconButton } from "@mui/material";
// componennts
import {
    PageTitle,
    SettingFieldForm,
    SideTitle,
    ThreeDotsLoader,
} from "../../components";
// alerts
import { successAlert, errorAlert } from "../../utils/alerts/alerts";
// yup
import * as yup from "yup";
// types
import { ReduxUserReducerState } from "../../types/global";

const TechnicalSettings = () => {
    // state
    const [loading, setLoading] = useState(true);
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/settings?page=1&paginate=200`)
            .then((res: AxiosResponse) => {
                const settingsData = res.data.data;
                settingsData.forEach((stg: any, index: number) => {
                    tlyncFieldsInfo.forEach((f: any) => {
                        if (stg.key === f.fieldKey) {
                            f.value = stg.value;
                            f.id = stg.id;
                            return;
                        }
                    });
                    anisFieldsInfo.forEach((f: any) => {
                        if (stg.key === f.fieldKey) {
                            f.value = stg.value;
                            f.id = stg.id;
                            return;
                        }
                    });
                });
                setLoading(false);
            });
    }, []);
    // refresh token
    const refreshAnisToken = async () => {
        EnhancedAxios(null, userToken)
            .put(`/admin/settings/anis-token`)
            .then((res: AxiosResponse) => {
                successAlert(" تم تحديث التوكن الخاص بانيس");
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="الضبط" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    divider={<Divider />}
                >
                    {/* t-lync info  */}
                    {tlyncFieldsInfo.length ? (
                        <Stack width="100%" gap="10px">
                            <SideTitle title="خصائص t-lync" />
                            {tlyncFieldsInfo.map((field: any) => (
                                <SettingFieldForm
                                    key={field.key}
                                    id={field.id}
                                    fieldKey={field.fieldKey}
                                    title={field.title}
                                    value={field.value}
                                    type={field.type}
                                    schema={field.schema}
                                />
                            ))}
                        </Stack>
                    ) : (
                        <></>
                    )}
                    {anisFieldsInfo.length ? (
                        <Stack width="100%" gap="10px">
                            <SideTitle title="خصائص انيس" />
                            {anisFieldsInfo.map((field: any) => (
                                <SettingFieldForm
                                    key={field.key}
                                    id={field.id}
                                    fieldKey={field.fieldKey}
                                    title={field.title}
                                    value={field.value}
                                    type={field.type}
                                    schema={field.schema}
                                />
                            ))}
                        </Stack>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default TechnicalSettings;

const tlyncFieldsInfo: any[] = [
    {
        id: 0,
        fieldKey: "tlync_endpoint",
        title: " t-lync endpoint",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل رابط بشكل صحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "tlync_token",
        title: "t-lync token",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string(),
        }),
    },
    {
        id: 0,
        fieldKey: "tlync_store_id",
        title: "t-lync store id",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string(),
        }),
    },
    {
        id: 0,
        fieldKey: "tlync_front_url",
        title: "t-lync front url",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string(),
        }),
    },
];
const anisFieldsInfo: any[] = [
    {
        id: 0,
        fieldKey: "anis_base_url",
        title: "Anis base url",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل رابط بشكل صحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_identity_url",
        title: "Anis identity url",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل رابط بشكل صحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_pin",
        title: "Anis pin",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.number().typeError("يجب ان يكون رقم صحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_password",
        title: "Anis password",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string().required("ادخل كلمة المرور"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_client_secret",
        title: "anis client secret",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string().required("ادخل  كلمة مرور العميل"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_client_id",
        title: "anis client id",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string().required("ادخل رقم ال ID"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_wallet_id",
        title: "anis wallet id",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string().required("ادخل رقم ال ID"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_phone",
        title: "Anis phone",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.number().typeError("يجب ان يكون رقم صحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "anis_email",
        title: "Anis email",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string().email("ادخل البريد الالكترونى بشكل صحيح"),
        }),
    },
];
