import { Stack, TextField } from "@mui/material";
import React from "react";
import SideTitle from "../../title/SideTitle";

const RateDetailsSection = ({ orderDetails }: any) => {
    return (
        <Stack gap="10px" width="100%">
            <SideTitle title="بيانات التقييم" />
            <Stack
                gap="10px"
                width="100%"
                sx={{
                    "& .MuiFormControl-root": {
                        width: "100%",
                        flex: "1",
                    },
                }}
            >
                <TextField
                    label="التقييم"
                    value={orderDetails?.review?.rate ?? "لا يوجد"}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="التعليق"
                    value={orderDetails?.review?.comment ?? "لا يوجد"}
                    multiline
                    minRows={2}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default RateDetailsSection;
