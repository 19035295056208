import React, { ChangeEvent, useState } from "react";
// mui
import { Switch } from "@mui/material";
// Components
import EnhancedAxios from "../../axios/EnhancedAxios";
import { useSelector } from "react-redux";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
type Props = {
    checked: boolean;
    endpointPath: string;
};
const DataGridSwitch = ({ checked, endpointPath }: Props) => {
    const userToken = useSelector((state: any) => state.user.userToken);

    const [disabledValue, setDisabledValue] = useState(!checked);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = !e.target.checked;
        const formData: any = new FormData();
        formData.append("is_disabled", v);
        EnhancedAxios("multipart/form-data", userToken)
            .put(endpointPath, formData)
            .then((res: any) => {
                const alertMsg =
                    res.data.is_disabled === true ? "تم التعطيل" : "تم التفعيل";
                successAlert(alertMsg);
                setDisabledValue(!res.data.is_disabled);
            });
    };
    return (
        <Switch size="small" checked={disabledValue} onChange={handleChange} />
    );
};

export default DataGridSwitch;
