import React, { useState, SetStateAction, Dispatch, ChangeEvent } from "react";
import { useSelector } from "react-redux";
// mui
import {
    Stack,
    Box,
    Typography,
    Avatar,
    Button,
    Switch,
    Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
// alets
import {
    askForConfirmationAlert,
    successAlert,
    errorAlert,
} from "../../utils/alerts/alerts";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
// utils
import { getRoleNameById } from "../../utils/roles/appRoles";
//types
import { ReduxUserReducerState } from "../../types/global";
type Props = {
    id: string;
    imgSrc: string | never;
    name: string | never;
    price: number | never;
    to: string | never;
    is_disabled: boolean;
    is_anis: boolean;
    setDataChanged: Dispatch<SetStateAction<boolean>>;
};

const ProductCard = ({
    id,
    imgSrc,
    name,
    price,
    to,
    is_disabled,
    is_anis = false,
    setDataChanged,
}: Props) => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);
    const [isActive, setIsActive] = useState(!is_disabled);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = !e.target.checked;
        const formData: any = new FormData();
        formData.append("is_disabled", v);
        EnhancedAxios(null, userToken)
            .put(`/${userRole}/items/${id}`, formData)
            .then((res: any) => {
                const alertMsg =
                    res.data.is_disabled === true ? "تم التعطيل" : "تم التفعيل";
                successAlert(alertMsg);
                setIsActive(!res.data.is_disabled);
            });
    };
    const deleteProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(`/${userRole}/items/${id}`)
            .then((res: any) => {
                successAlert("تم حذف العنصر");
                setDataChanged((prev: boolean) => !prev);
            })
            .catch((error: any) => {
                errorAlert(error);
            });
    };
    const deleteConfirmation = () => {
        askForConfirmationAlert(
            `هل انت متاكد انك تريد حذف المنتج "${name}"`,
            deleteProcess
        );
    };
    return (
        <Stack
            gap="10px"
            // flex="1"
            width="290px"
            minWidth="290px"
            sx={{
                border: "1px solid #e6e6e6",
                width: { xs: "290px", sm: "320px" },
                borderRadius: "5px",
                transition: "background .3s ease",
                position: "relative",
                "&:hover": {
                    boxShadow: 2,
                },
                opacity: !isActive ? ".8" : "1",
            }}
        >
            {/* {isActive ? (
                <Chip
                    label="مفعل"
                    size="small"
                    variant="filled"
                    color="success"
                    sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        zIndex: 3,
                    }}
                />
            ) : (
                <Chip
                    label="معطل"
                    size="small"
                    variant="filled"
                    sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        backgroundColor: "#ddd",
                    }}
                />
            )} */}
            <Stack
                direction="row"
                gap="3px"
                sx={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    zIndex: "2",
                }}
            >
                <Chip
                    label={isActive ? "مفعل" : "معطل"}
                    size="small"
                    variant="filled"
                    // color={isActive ? "success" : "info"}
                    sx={{
                        backgroundColor: isActive ? "success.main" : "#ddd",
                        color: isActive ? "#f1f1f1" : "#111",
                    }}
                />
                {is_anis ? (
                    <Chip
                        label="منتج آنيس"
                        size="small"
                        variant="filled"
                        sx={{
                            backgroundColor: "#e76b1d",
                            color: "#f1f1f1",
                        }}
                    />
                ) : (
                    <></>
                )}
            </Stack>
            <Avatar
                src={imgSrc}
                alt={name}
                variant="square"
                sx={{
                    width: "100%",
                    height: "150px",
                    "& .muirtl-1pqm26d-MuiAvatar-img": { objectFit: "contain" },
                }}
            />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                p={1}
            >
                <Typography variant="subtitle2">{name}</Typography>
                <Typography variant="subtitle2">{price}</Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap="5px"
                p={1}
            >
                <Stack direction="row" spacing="10px">
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to={to}
                    >
                        تعديل
                    </Button>
                    {/* <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={deleteConfirmation}
                    >
                        حذف
                    </Button> */}
                </Stack>
                <Switch
                    size="small"
                    checked={isActive}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    );
};

export default ProductCard;
