import { useState } from "react";
// react router
import { useNavigate } from "react-router";
// react redux
import { useDispatch } from "react-redux";
// mui
import { Button, Stack, TextField } from "@mui/material";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";
// react hook form  and validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../schemes/loginSchema";
// components
import PasswordField from "./PasswordField";
import { SubmitButton } from "../../components";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// acions
import { setUserToken, setUserData } from "../../redux/features/user/userSlice";
// appRoles
import { appRoles } from "../../utils/roles/appRoles";

type FormValues = {
    email: string;
    password: string;
};
const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [submittingForm, setSubmittingForm] = useState(false);
    // --------------------------- form handle ---------------------------
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<FormValues>({
        defaultValues: {
            email: "",
            password: "",
        },
        resolver: yupResolver(loginSchema),
        mode: "onChange",
    });

    // submit form
    const submitForm = (data: FormValues) => {
        setSubmittingForm(true);
        EnhancedAxios(null, null)
            .post(`/login`, data)
            .then((res: AxiosResponse) => {
                EnhancedAxios(null, res.data.token.value)
                    .get("/me")
                    .then((meRes: AxiosResponse) => {
                        successAlert("تم تسجيل الدخول بنجاح");
                        dispatch(setUserToken(res.data.token.value));
                        dispatch(setUserData(meRes.data));
                        setSubmittingForm(false);
                        if (meRes.data.roles.includes(appRoles.admin)) {
                            navigate("/");
                        } else if (
                            meRes.data.roles.includes(appRoles.operator)
                        ) {
                            navigate("/anis-products");
                        } else {
                            navigate("/login");
                        }
                    })
                    .catch((error: AxiosError) => {
                        errorAlert(error);
                        setSubmittingForm(false);
                    });
            })
            .catch((error: ErrorCallback) => {
                errorAlert(error);
                setSubmittingForm(false);
            });
    };
    // --------------------------- component ui ---------------------------
    return (
        <Stack
            spacing="10px"
            width="100%"
            maxWidth="700px"
            component="form"
            onSubmit={handleSubmit(submitForm)}
        >
            <TextField
                label="البريد الالكترونى"
                {...register("email")}
                error={errors.email ? true : false}
                helperText={errors?.email?.message}
            />
            <PasswordField
                label="الرقم السرى"
                registration={{ ...register("password") }}
                errorMessage={errors.password ? errors.password.message : null}
            />
            <SubmitButton
                label="تسجيل الدخول"
                disabled={submittingForm ? true : false}
            />
        </Stack>
    );
};

export default LoginForm;
