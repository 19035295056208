import * as yup from "yup";
export const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email("ادخل البريد الالكترنى بشكل صحيح")
        .required("ادخل البريد الالكترونى"),
    password: yup
        .string()
        .min(8, "الرقم السرى قصير جدا")
        .max(32, "الرقم السري طويل حدا")
        .required("من فضلك ادخل الرقم السري"),
});
