import * as yup from "yup";

const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;

export const addCategorySchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : "t")
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            value != null
                ? ["image/jpg", "image/png", "image/jpeg"].includes(value.type)
                : "t"
        ),
    name: yup
        .string()
        .min(3, "اسم التصنيف يجب ان يكون اكبر من 3 حروف")
        .required("ادخل اسم التصنيف المراد انشاؤه"),
});
