import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    SelectChangeEvent,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
type TypeOption = {
    label: string;
    value: string;
};
const productTypes: TypeOption[] = [
    {
        label: "الكل",
        value: "is_digital:false|true",
    },
    {
        label: "منتج ملموس",
        value: "is_digital:false",
    },
    {
        label: "منتج غير ملموس",
        value: "is_digital:true",
    },
];

type Props = {
    filterValues: {
        type: string;
    };
    setFilterValues: Dispatch<SetStateAction<{ type: string }>>;
};

const ProductType = ({ filterValues, setFilterValues }: Props) => {
    const handleChange: any = (e: SelectChangeEvent<string>) => {
        setFilterValues({
            ...filterValues,
            type: e.target.value,
        });
    };
    return (
        <Box
            sx={{
                flex: "1",
                minWidth: "230px",
            }}
        >
            <FormControl fullWidth>
                <InputLabel>ملموس / غير ملموس</InputLabel>
                <Select
                    name="status_filter"
                    value={filterValues.type}
                    label="ملموس / غير ملموس"
                    onChange={handleChange}
                >
                    {productTypes.map((option: TypeOption) => {
                        return (
                            <MenuItem value={option.value} key={option.value}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default ProductType;
