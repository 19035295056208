import * as yup from "yup";

const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;

export const addProductWithAnisSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : "t")
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            value != null
                ? ["image/jpg", "image/png", "image/jpeg"].includes(value.type)
                : "t"
        )
        .test(
            "fileRequired",
            "يجب ادخال صورة المنتج",
            (value) => value !== null
        ),
    name: yup
        .string()
        .min(3, "اسم المنج قصير جدا")
        .required("ادخل  الاسم الصحيح"),
    is_anis_global_mod: yup.boolean(),
    anis_price_mod: yup
        .mixed()
        .when("is_anis_global_mod", (is_anis_global_mod, schema) => {
            if (is_anis_global_mod) return schema;
            else
                return schema
                    .number()
                    .typeError("يجب ان يكون سعر المنتج رقم ")
                    .min(0.0, "معدل السعر يجب ان يكون رقم اكبر من الصفر")
                    .required("ادخل سعر المنتج");
        }),
    categories: yup
        .mixed()
        .test("check-value", "اختر الفئة خاصة المنتج", (value) => {
            return value && value.length;
        }),
    anis_fixed_price: yup
        .number()
        .min(0, "يجب ان يكون الرقم اكبر من 0")
        .typeError("ادخل سعر انيس الثابت "),
    unit: yup.mixed().test("check-value", "اختر الوحدة", (value) => {
        return value;
    }),
});
