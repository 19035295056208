import React, { ChangeEvent } from "react";
// mui
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
} from "@mui/material";
// hook form
import { Controller } from "react-hook-form";

type Props = {
    errorMessage: string | undefined;
    control: any;
};

const SelectTransactionType = ({ errorMessage, control }: Props) => {
    return (
        <FormControl fullWidth error={errorMessage ? true : false}>
            <InputLabel>نوع العملية</InputLabel>
            <Controller
                name="type"
                control={control}
                render={({ field }) => (
                    <Select label="نوع العملية" {...field}>
                        {transactionTypes.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {errorMessage ? (
                <FormHelperText id="type-error-msg">
                    {errorMessage}
                </FormHelperText>
            ) : (
                <></>
            )}
        </FormControl>
    );
};

export default SelectTransactionType;

const transactionTypes = [
    {
        label: "ايداع",
        value: "credit",
    },
    {
        label: "سحب",
        value: "debit",
    },
];
