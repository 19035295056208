import React from "react";
// component
import { LinkButton, DataGridSwitch } from "../../components";
// mui
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
// moment
import moment from "moment";

export const operatorsCols: GridColDef[] = [
    {
        field: "name",
        headerName: "الاسم",
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "لا يوجد",
    },
    {
        field: "email",
        headerName: "البريد الالكترونى",
        width: 250,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.email ?? "لا يوجد",
    },
    {
        field: "created_at",
        headerName: "تاريخ الانشاء ",
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD HH:MM")
                : "لا يوجد",
    },
    {
        field: "edit",
        headerName: "تعديل ",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(LinkButton, {
                text: "تعديل",
                to: `/edit-operator/${params.row.id}`,
                size: "small",
                variant: "outlined",
            }),
    },
    {
        field: "status",
        headerName: "الحالة",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(DataGridSwitch, {
                checked: params?.row?.is_disabled,
                endpointPath: `/admin/users/${params?.row?.id}`,
            }),
    },
];
