import { Dispatch, SetStateAction } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    SelectChangeEvent,
} from "@mui/material";

type StatusFilter = {
    filterTitle: string;
    filterKey: string;
};
// filters option
const statusFiltersOptions: StatusFilter[] = [
    {
        filterTitle: "الكل",
        filterKey: "status:eq:completed|pending|preparing",
    },
    {
        filterTitle: "تم التوصيل",
        filterKey: "status:eq:completed",
    },
    {
        filterTitle: "قيد الانتظار",
        filterKey: "status:eq:pending",
    },
    {
        filterTitle: "قيد التجهيز",
        filterKey: "status:eq:preparing",
    },
];

type Props = {
    filterValues: {
        status?: string;
    };
    setFilterValues: Dispatch<SetStateAction<{ status: string }>>;
};

const OrderStatusFilterBox = ({ filterValues, setFilterValues }: Props) => {
    const handleChange: any = (e: SelectChangeEvent<string>) => {
        setFilterValues({
            ...filterValues,
            status: e.target.value,
        });
    };
    return (
        <Box
            sx={{
                flex: "1",
                minWidth: "230px",
            }}
        >
            <FormControl fullWidth>
                <InputLabel> حالة الطلب</InputLabel>
                <Select
                    name="status_filter"
                    value={filterValues.status}
                    label="حالة الطلب"
                    onChange={handleChange}
                >
                    {statusFiltersOptions.map((option: StatusFilter) => {
                        return (
                            <MenuItem
                                value={option.filterKey}
                                key={option.filterKey}
                            >
                                {option.filterTitle}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default OrderStatusFilterBox;
