import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// react rouoter
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Stack, FormControlLabel, Switch, Divider } from "@mui/material";
// components
import {
    SideTitle,
    SubmitButton,
    InputField,
    SelectGender,
    ThreeDotsLoader,
} from "../../components";
// scheme
import { editUserSchema } from "../../schemes/user/editUserSchema";
// types
import { ReduxUserReducerState } from "../../types/global";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
import moment from "moment";
type FormValues = {
    name: string;
    gender: string;
    birthdate: string;
    email: string;
    is_disabled: boolean;
    is_verified: boolean;
    credit: number;
};

const EditUserForm = () => {
    const { userId } = useParams<string>();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    // states
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    // form config
    const form = useForm<FormValues>({
        defaultValues: {
            name: "",
            gender: "",
            birthdate: "",
            email: "",
            is_disabled: false,
            credit: 0,
        },
        resolver: yupResolver(editUserSchema),
        mode: "all",
    });
    const {
        register,
        control,
        watch,
        setValue,
        reset,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        const newData = {
            ...data,
            birthdate: moment(data.birthdate).format("YYYY-MM-DD"),
        };
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .put(`/admin/users/${userId}`, newData)
            .then((response: AxiosResponse) => {
                setSubmitting(false);
                successAlert("تم تعديل بيانات المستخدم ");
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // get user details
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/users/${userId}`)
            .then((res: AxiosResponse) => {
                reset({
                    name: res?.data?.name ?? "",
                    gender: res?.data?.gender ?? "",
                    email: res?.data?.email ?? "",
                    birthdate: res?.data?.birthdate ?? "",
                    is_disabled: res?.data?.is_disabled ?? false,
                    credit: res?.data?.wallet?.credit ?? 0,
                });
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, []);
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack
            width="100%"
            maxWidth="700px"
            gap="10px"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <SideTitle title="البيانات الاساسية" />
            <InputField
                type="text"
                registration={{ ...register("name") }}
                label="الاسم"
                errorMessage={errors?.name?.message}
            />
            <InputField
                type="email"
                registration={{ ...register("email") }}
                label="البريد الالكترونى"
                errorMessage={errors?.email?.message}
            />
            <SelectGender
                errorMessage={errors?.gender?.message}
                control={control}
            />
            <InputField
                type="date"
                registration={{ ...register("birthdate") }}
                label="تاريخ الميلاد"
                errorMessage={errors?.birthdate?.message}
                defaultValue={new Date()}
            />
            <InputField
                type="text"
                registration={{ ...register("credit") }}
                label="رصيد المحفظة"
                errorMessage={errors?.credit?.message}
                readOnly={true}
            />
            <Stack direction="row">
                <Controller
                    name="is_disabled"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    {...field}
                                    checked={!watch("is_disabled")}
                                    onChange={(e) =>
                                        setValue(
                                            "is_disabled",
                                            !e.target.checked
                                        )
                                    }
                                />
                            }
                            label="مفعل"
                        />
                    )}
                />
            </Stack>
            <SubmitButton
                label="تعديل"
                disabled={submitting || !Object.keys(dirtyFields).length}
            />
            <Divider />
        </Stack>
    );
};

export default EditUserForm;
