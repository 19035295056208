import React from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import AnisSubCategoryCard from "../card/AnisSubCategoryCard";
type Prop = {
    parentCategoryName: string;
    open: boolean;
    handleClose: () => void;
    subCategories?: {
        id: string;
        name: string;
        description: string;
        logo: string;
        inStock: boolean;
        hasSpecialOffer: boolean;
    }[];
};
const AnisSubCategoriesModal = ({
    open,
    handleClose,
    subCategories,
    parentCategoryName,
}: Prop) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ "& .MuiPaper-root": { maxWidth: "900px" } }}
        >
            <DialogTitle>{parentCategoryName}</DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="20px"
                        flexWrap="wrap"
                    >
                        {subCategories && subCategories?.length ? (
                            subCategories.map((sub) => (
                                <AnisSubCategoryCard
                                    key={sub.id}
                                    id={sub?.id}
                                    logo={sub?.logo}
                                    name={sub?.name}
                                />
                            ))
                        ) : (
                            <p>no categories</p>
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AnisSubCategoriesModal;
