import React from "react";
// logo
import logo from "../../assets/logo_150.png";
// mui
import { Avatar, Stack, Typography } from "@mui/material";
// package json
import packageJson from "../../../package.json";
const NavbarLogo = () => {
    return (
        <Stack spacing="5px">
            <Avatar
                src={logo}
                variant="square"
                sx={{
                    width: "150px",
                    height: "150px",
                    // background: "white",
                    "& .MuiAvatar-img": {
                        // width: "100px",
                        // height: "100px",
                        objectFit: "contain",
                    },
                }}
            />
            <Typography
                variant="body2"
                sx={{ color: "#fff", textAlign: "center" }}
            >
                V {packageJson.version}
            </Typography>
        </Stack>
    );
};

export default NavbarLogo;
