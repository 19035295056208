import React from "react";
// react router
import { Outlet } from "react-router-dom";
// mui
import { Box, Container } from "@mui/material";

const AuthLayout = () => {
    return (
        <Box minHeight="100vh">
            <Container>
                <Box width="100%">
                    <Outlet />
                </Box>
            </Container>
        </Box>
    );
};

export default AuthLayout;
