import React, {
    useState,
    useEffect,
    FC,
    Dispatch,
    SetStateAction,
} from "react";
// mui
import { Box, Stack, Container, IconButton, Typography } from "@mui/material";
// components
import NavbarLogo from "./NavbarLogo";
import NavbarLinks from "./NavbarLinks";
import LogoutButton from "./LogoutButton";
// icons and images
import { FaAngleDoubleRight } from "react-icons/fa";

// toast
type Props = {
    navbarOpen: boolean;
    setNavbarOpen: Dispatch<SetStateAction<boolean>>;
};
const Navbar = ({ navbarOpen, setNavbarOpen }: Props) => {
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setNavbarOpen(false);
            } else {
                setNavbarOpen(true);
            }
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [windowSize, navbarOpen]);

    if (!navbarOpen) return <></>;
    return (
        <Stack
            className="hide-scrollbar"
            sx={{
                height: "100vh",
                width: "270px",
                backgroundColor: "primary.main",
                overflow: "auto",
                transition: "transform .3s ease",
                position: { xs: "fixed", md: "relative" },
                transform: `scaleX(${navbarOpen ? "1" : "0"})`,
                transformOrigin: "left",
                zIndex: "99",
            }}
        >
            <Container sx={{ height: "100%" }}>
                <Stack spacing="20px" sx={{ padding: "30px 0" }}>
                    <Stack width="100%" spacing={4} alignItems="center">
                        <IconButton
                            sx={{
                                marginRight: "auto",
                                display: { xs: "flex", md: "none" },
                            }}
                            onClick={() => setNavbarOpen(false)}
                        >
                            <FaAngleDoubleRight />
                        </IconButton>
                        <NavbarLogo />
                    </Stack>
                    <Stack
                        spacing={2}
                        width="100%"
                        flex="1"
                        justifyContent="space-between"
                        sx={{ color: "basicColor.white" }}
                    >
                        <NavbarLinks />
                        <LogoutButton />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default Navbar;
