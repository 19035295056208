import { useEffect, useState, ChangeEvent } from "react";
// react router
import { useParams, useNavigate } from "react-router";
// redux
import { useSelector } from "react-redux";
// react hook form  and validation
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editDigitalProductSchema } from "../../schemes/product/editDigitalProductSchema";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import {
    Stack,
    Box,
    Switch,
    FormControlLabel,
    Typography,
} from "@mui/material";
// components
import {
    PageTitle,
    ImagePreview,
    SubmitButton,
    InputField,
    SelectCategory,
    SelectUnit,
    ErrorMessage,
    ThreeDotsLoader,
} from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// utils
import { getRoleNameById } from "../../utils/roles/appRoles";
// types
import { ReduxUserReducerState } from "../../types/global";

type FormValues = {
    img: File | null | string;
    name: string;
    price: number | undefined;
    final_price: number | undefined;
    quantity: number;
    ignore_quantity: boolean;
    description: string;
    categories: any;
    unit: any;
    csv: File | null;
    is_digital: boolean;
    is_disabled: boolean;
    is_anis_global_mod: boolean;
    anis_price_mod: number | null;
    anis_fixed_price: number;
};

type AnisData = {
    arabicName: null | string;
    businessPrice: null | number;
    cost: null | number;
    currencyType: null | string;
    englishName: null | string;
    faceValue: null | number;
    hasSpecialOffer: null | boolean;
    id: null | string;
    inStock: null | boolean;
    logo: null | string;
    name: null | string;
    personalPrice: null | number;
    price: null | number;
    printLogoName: null | string;
    printLogoPath: null | string;
    specialOfferPrice: null | string | number;
    is_anis_global_mod: null | boolean;
    anis_price_mod: null | boolean;
    anis_fixed_price: number;
};
const EditDigitalProduct = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultImg, setDefaultImg] = useState("");
    const [isAnis, setIsAnis] = useState(false);
    const [anisData, setAnisData] = useState<AnisData>({
        arabicName: null,
        businessPrice: null,
        cost: null,
        currencyType: null,
        englishName: null,
        faceValue: null,
        hasSpecialOffer: null,
        id: null,
        inStock: null,
        logo: null,
        name: null,
        personalPrice: null,
        price: null,
        printLogoName: null,
        printLogoPath: null,
        specialOfferPrice: null,
        is_anis_global_mod: null,
        anis_price_mod: null,
        anis_fixed_price: 0,
    });
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            name: "",
            price: 0,
            final_price: 0,
            quantity: 0,
            ignore_quantity: false,
            description: "",
            categories: [],
            unit: "",
            csv: null,
            is_digital: true,
            is_disabled: false,
            is_anis_global_mod: false,
            anis_price_mod: 0,
            anis_fixed_price: 0,
        },
        resolver: yupResolver(editDigitalProductSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        control,
        watch,
        reset,
        getValues,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        // reshape categoreis array
        const newCats: any = [];
        data.categories.forEach((cat: any) => {
            newCats.push(cat.id);
        });
        // overwrite new shape of categories to the new DAta
        const newData = { ...data, categories: newCats };
        // convert new data to formData
        const formData = new FormData();
        if (watch("ignore_quantity")) {
            data["quantity"] = 0;
        }
        for (let i in newData) {
            if (i == "categories") {
                newData.categories.forEach((cat: any) => {
                    formData.append("categories", cat);
                });
            } else {
                formData.append(i, newData[i]);
            }
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/${userRole}/items/${productId}`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم تعديل منتج باسم ${data.name}`);
                setSubmitting(false);
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };

    // get product data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/${userRole}/items/${productId}`)
            .then((res: AxiosResponse) => {
                reset(res.data);
                setDefaultImg(res.data.img);
                reset({
                    img: res.data.img,
                    name: res.data.name,
                    price: res.data.price,
                    final_price: res.data.final_price,
                    quantity: res.data.quantity,
                    ignore_quantity: res.data.ignore_quantity,
                    description: res.data.description,
                    categories: res.data.categories,
                    unit: JSON.stringify({ id: res.data.unit.id }),
                    is_digital: res.data.is_digital,
                    is_disabled: res.data.is_disabled,
                    is_anis_global_mod: res.data.is_anis_global_mod,
                    anis_price_mod: res.data.anis_price_mod,
                    anis_fixed_price: res.data.anis_fixed_price ?? 0,
                });
                setIsAnis(res.data.is_anis);
                setAnisData(res.data.anis_data);
                setLoading(false);
            });
    }, []);
    // while page is loading
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="تعديل منتج غير ملموس" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message}
                        defaultImage={defaultImg}
                        IPObjectFit="contain"
                    />
                    {isAnis ? <ProductAnisData anisData={anisData} /> : <></>}
                    <InputField
                        type="text"
                        label="اسم المنتج"
                        registration={{ ...register("name") }}
                        errorMessage={errors?.name?.message}
                    />
                    <InputField
                        type="number"
                        label="سعر المنتج"
                        registration={{ ...register("price") }}
                        errorMessage={errors?.price?.message}
                        disabled={isAnis}
                    />
                    <InputField
                        type="number"
                        label="نسبة التخفيض"
                        registration={{ ...register("final_price") }}
                        errorMessage={errors?.final_price?.message}
                        disabled={isAnis}
                    />
                    {isAnis ? (
                        <InputField
                            type="number"
                            label="سعر انيس الثابت"
                            registration={{ ...register("anis_fixed_price") }}
                            errorMessage={errors?.anis_fixed_price?.message}
                        />
                    ) : (
                        <></>
                    )}
                    {isAnis ? (
                        <Stack
                            direction="row"
                            gap="12px"
                            width="100%"
                            sx={{ "& .MuiFormControl-root": { flex: "1" } }}
                        >
                            <InputField
                                type="number"
                                label="معدل السعر الخاص"
                                registration={{ ...register("anis_price_mod") }}
                                errorMessage={errors?.anis_price_mod?.message}
                                disabled={
                                    watch("is_anis_global_mod") ||
                                    watch("anis_fixed_price") > 0
                                }
                            />

                            <Controller
                                name="is_anis_global_mod"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        disabled={watch("anis_fixed_price") > 0}
                                        control={
                                            <Switch
                                                {...field}
                                                checked={watch(
                                                    "is_anis_global_mod"
                                                )}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>
                                                ) =>
                                                    setValue(
                                                        "is_anis_global_mod",
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="استخدم معدل السعر العام"
                                    />
                                )}
                            />
                        </Stack>
                    ) : (
                        <></>
                    )}
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                    >
                        <Box flex="1">
                            <InputField
                                type="number"
                                label="الكمية"
                                registration={{ ...register("quantity") }}
                                errorMessage={errors?.quantity?.message}
                                disabled={
                                    watch("ignore_quantity") ? true : false
                                }
                                styles={{ width: "100%" }}
                            />
                        </Box>
                        <Controller
                            name="ignore_quantity"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...field}
                                            checked={watch("ignore_quantity")}
                                            onChange={(e) =>
                                                setValue(
                                                    "ignore_quantity",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="تجاهل الكمية"
                                />
                            )}
                        />
                    </Box>
                    <SelectUnit
                        control={control}
                        defaultValue={watch("unit")}
                    />
                    {errors?.unit ? (
                        <ErrorMessage errMessage={errors?.unit?.message} />
                    ) : (
                        <></>
                    )}
                    <SelectCategory
                        control={control}
                        watch={watch}
                        setValue={setValue}
                    />
                    {errors?.categories ? (
                        <ErrorMessage
                            errMessage={errors?.categories?.message}
                        />
                    ) : (
                        <></>
                    )}
                    <InputField
                        type="text"
                        label="وصف المنتج"
                        registration={{ ...register("description") }}
                        errorMessage={errors?.description?.message}
                        multiline={true}
                        minRows={3}
                        maxRows={6}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="تعديل" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditDigitalProduct;

const ProductAnisData = ({ anisData }: { anisData: AnisData }) => {
    return (
        <Stack direction="row" gap="10px" flexWrap="wrap">
            <AnisDataBox label="الاسم" value={anisData.name ?? "لا يوجد"} />
            <AnisDataBox label="السعر" value={anisData.price ?? "لا يوجد"} />
            <AnisDataBox label="التكلفة" value={anisData.cost ?? "لا يوجد"} />
            <AnisDataBox
                label="سعر العرض الخاص"
                value={anisData.specialOfferPrice ?? "لا يوجد"}
            />
            <AnisDataBox
                label="السعر التجارى"
                value={anisData.businessPrice ?? "لا يوجد"}
            />
            <AnisDataBox
                label="السعر للفرد"
                value={anisData.personalPrice ?? "لا يوجد"}
            />
            <AnisDataBox
                label="نوع العملة"
                value={anisData.currencyType ?? "لا يوجد"}
            />
            <AnisDataBox
                label="القيمة الاسمية"
                value={anisData.faceValue ?? "لا يوجد"}
            />
            <AnisDataBox
                label="يوجد عرض خاص"
                value={anisData.hasSpecialOffer ? "✔" : "✖"}
            />
            <AnisDataBox label="متاحة" value={anisData.inStock ? "✔" : "✖"} />
        </Stack>
    );
};

const AnisDataBox = ({
    label,
    value,
}: {
    label: string;
    value: string | number | null;
}) => {
    return (
        <Stack
            gap="10px"
            sx={{
                minWidth: "150px",
                flex: "1",
                padding: "10px",
                boxShadow: 1,
                border: "1px solid #ddd",
                borderRadius: "5px",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="body2">{label}</Typography>
            <Typography variant="body2">{value}</Typography>
        </Stack>
    );
};
