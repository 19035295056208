import React, { FC } from "react";
import { Typography } from "@mui/material";
type Props = {
    title: string;
};
const PageTitle = ({ title }: Props) => {
    return (
        <Typography
            variant="h3"
            fontWeight="500"
            textAlign="center"
            marginBottom="20px"
        >
            {title}
        </Typography>
    );
};

export default PageTitle;
