import React, { useState, useEffect } from "react";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// reacthook form
import {
    UseFormGetValues,
    UseFormRegisterReturn,
    UseFormSetValue,
    Controller,
} from "react-hook-form";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { errorAlert } from "../../utils/alerts/alerts";
import { useSelector } from "react-redux";

type Props = {
    getValues?: UseFormGetValues<any>;
    errorMessage?: string | undefined;
    setValue: UseFormSetValue<any>;
    control: any;
    watch?: any;
    multiple?: boolean;
    name?: string;
    categoriesParent?: string;
};

const SelectCategory = ({
    getValues,
    errorMessage,
    setValue,
    control,
    watch,
    multiple = true,
    name = "categories",
    categoriesParent = process.env.REACT_APP_ITEMS_CATS_PARENT_ID,
}: Props) => {
    const userToken = useSelector((state: any) => state.user.userToken);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    // get alll categories
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(
                `/categories?filters=parent_id:${categoriesParent}&sorts=-name&page=1&paginate=120`
            )
            .then((res: AxiosResponse) => {
                const newCategoriesForm = res.data.data.map((cat: any) => {
                    const v = { id: cat.id, name: cat.name };
                    return v;
                });
                setCategories(newCategoriesForm);
                setLoading(false);
            })
            .catch((error: AxiosError) =>
                errorAlert(
                    "حدث خطا اثناء العرص التصنيفات برجاء اعادة تحميل الصفحة"
                )
            );
    }, []);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    multiple={multiple}
                    sx={{ width: "100%" }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                        option?.id === value?.id
                    }
                    getOptionLabel={(option: any) => {
                        return option?.name;
                    }}
                    {...field}
                    options={categories}
                    loading={loading}
                    // value={watch("categories")}
                    onChange={(e, item: any) => {
                        setValue(name, item);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="التصنيف"
                            helperText={errorMessage}
                            error={errorMessage ? true : false}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <AiOutlineLoading3Quarters />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default SelectCategory;
