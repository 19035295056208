import React from "react";
import { Typography } from "@mui/material";
const ErrorMessage = ({ errMessage }) => {
    return (
        <Typography fontSize="12px" color="error">
            {errMessage}
        </Typography>
    );
};

export default ErrorMessage;
