import * as yup from "yup";
const phoneRegex = new RegExp(
    /[+]?([0-9]{3})?\s?[\-]?[0-9]{2,3}\s?[\-]?[0-9]{3}\s?[\-]?[0-9]{4}/
);
const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;
export const editDelivererSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجد الصورة ${maxImgSizeMB}MB`,
            (value) => imageEditSizeValidate(value)
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            imageEditTypeValidate(value)
        ),
    name: yup
        .string()
        .min(3, "اسم المدينة يجب ان يكون اكبر من 3 حروف")
        .required("ادخل اسم المدينة المراد انشاؤه"),
    address: yup.object().shape({
        name: yup
            .string()
            .min(3, "العنوان المختصر قصير جدا")
            .required("العنوان مطلوب"),
        details: yup
            .string()
            .min(10, "العنوان بالتفصيل قصير جدا")
            .required("العنوان مطلوب"),
        // city: yup
        //     .mixed()
        //     .nullable()
        //     .test(
        //         "null",
        //         "المدينة التابع لها المنطقة مطلوبة",
        //         (value) => value
        //     ),
        area: yup
            .mixed()
            .nullable()
            .test("null", "المنطقة مطلوبة", (value) => value),
    }),
    contactable: yup.array().of(
        yup.object().shape({
            key: yup.string().required("اختر الحقل المطلوب"),
            // value: yup.string(),
            value: yup.string().when("key", ([key], schema) => {
                return key === "phone" || key === "whatsapp"
                    ? schema
                          .matches(
                              phoneRegex,
                              "كتابة رقم الهاتف مسبوق بكود الدولة"
                          )
                          .required("ادخل الرقم الصحيح")
                    : schema
                          .url("الرابط ليس صحيح")
                          .required("الرابط الخاص بالمنصة المختارة");
            }),
        })
    ),
});

const imageEditSizeValidate = (value) => {
    if (value != null && typeof value != "string") {
        return value.size <= maxImgSizeBytes;
    }
    return true;
};

const imageEditTypeValidate = (value) => {
    if (value != null && typeof value != "string") {
        return ["image/jpg", "image/png", "image/jpeg"].includes(value.type);
    }
    return true;
};
