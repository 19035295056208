import React, { ChangeEvent, useEffect, useState } from "react";
// react router
import { Navigate, useNavigate, useParams } from "react-router";
// react hook form  and validation
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addProductWithAnisSchema } from "../../schemes/product/addProductWithAnisSchema";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import {
    Autocomplete,
    Box,
    FormControlLabel,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
// components
import {
    PageTitle,
    SelectUnit,
    ThreeDotsLoader,
    SelectCategory,
    SubmitButton,
    InputField,
    SideTitle,
    ErrorMessage,
    ImagePreview,
} from "../../components";
// types
import { ReduxUserReducerState } from "../../types/global";
// react redux
import { useSelector } from "react-redux";
// icons
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// urils
import { getRoleNameById } from "../../utils/roles/appRoles";
// types
type CardOption = {
    id?: string;
    name?: string;
    arabicName?: string;
    englishName?: string;
    price?: number | null;
    cost?: number | null;
    specialOfferPrice?: number | null;
    businessPrice?: number | null;
    personalPrice?: number | null;
    currencyType?: number | null;
    faceValue?: string;
    logo?: string;
    printLogoPath?: string;
    printLogoName?: string;
    inStock?: boolean;
    hasSpecialOffer?: boolean;
};
type FormValues = {
    img: File | null;
    name: string;
    description: string;
    unit: any;
    is_digital: boolean;
    is_disabled: boolean;
    anis_price_mod: number;
    is_anis_global_mod: boolean;
    categories: any;
    anis_fixed_price: number;
};

const ConnectProductWithAnis = () => {
    const navigate = useNavigate();
    const { anisCategoryId } = useParams();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);

    const [loading, setLoading] = useState(true);
    const [availableCards, setAvailableCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState<CardOption>({});
    const [cardsParentInfo, setCardsParentInfo] = useState({
        name: "",
        description: "",
    });
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    // fetch available cards
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/${userRole}/anis-items/categories/${anisCategoryId}`)
            .then((res: AxiosResponse) => {
                setAvailableCards(res.data.data.cards);
                setSelectedCard(res.data.data.cards[0]);
                setCardsParentInfo({
                    name: res.data.data.name,
                    description: res.data.data.description,
                });
                setLoading(false);
            });
    }, []);
    // form configration
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            unit: "",
            name: "",
            description: "",
            is_digital: true,
            is_disabled: false,
            is_anis_global_mod: true,
            categories: [],
            anis_price_mod: 0,
            anis_fixed_price: 0,
        },
        resolver: yupResolver(addProductWithAnisSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        control,
        watch,
        reset,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        if (data.is_anis_global_mod) data.anis_price_mod = null;
        // reshape categoreis array
        const newCats: any = [];
        data.categories.forEach((cat: any) => {
            newCats.push(cat.id);
        });
        // overwrite new shape of categories to the new DAta
        const finalData = {
            ...data,
            categories: newCats,
            card_id: selectedCard.id,
        };
        const formData = new FormData();
        for (let key in finalData) {
            formData.append(key, finalData[key]);
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/${userRole}/anis-items/sync`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم اضافة منتج باسم ${data.name}`);
                setSubmitting(false);
                navigate(`/edit-digital-product/${res.data.id}`);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // component ui
    return loading ? (
        <ThreeDotsLoader />
    ) : (
        <Stack
            gap="10px"
            sx={{ width: "100%", maxWidth: "800px", margin: "auto !important" }}
        >
            <PageTitle title="ربط مع منتجات أنيس" />
            {/* cards options  */}
            <Stack gap="10px">
                <Autocomplete
                    disableClearable
                    sx={{ width: "100%" }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    isOptionEqualToValue={(option: any, value: any) =>
                        option?.id == value?.id
                    }
                    getOptionLabel={(option: any) =>
                        // `${cardsParentInfo.name} | ${option?.arabicName}`
                        `${option?.arabicName} | ${option?.englishName} `
                    }
                    options={availableCards}
                    loading={loading}
                    value={selectedCard ?? availableCards[0]}
                    onChange={(e, item: any) => {
                        setSelectedCard(item ?? availableCards[0]);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={`الكروت المتاحة خاصة ${cardsParentInfo.name}`}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <AiOutlineLoading3Quarters />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "center",
                        gap: "10px",
                        flexWrap: "wrap",
                    }}
                >
                    <SelectedCardOptionsInfoCardImg
                        label={selectedCard.arabicName ?? ""}
                        img={selectedCard.logo ?? ""}
                    />
                    <SelectedCardOptionsInfoCard
                        label="السعر"
                        value={selectedCard?.price ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="التكلفة"
                        value={selectedCard?.cost ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="سعر العرض الخاص"
                        value={selectedCard?.specialOfferPrice ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="السعر التجارى"
                        value={selectedCard?.businessPrice ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="السعر للفرد"
                        value={selectedCard?.personalPrice ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="نوع العملة"
                        value={selectedCard?.currencyType ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="القيمة الاسمية"
                        value={selectedCard?.faceValue ?? "لا يوجد"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="يوجد عرض خاص"
                        value={selectedCard?.hasSpecialOffer ? "✔" : "✖"}
                    />
                    <SelectedCardOptionsInfoCard
                        label="متاحة"
                        value={selectedCard?.inStock ? "✔" : "✖"}
                    />
                </Box>
            </Stack>
            {/* product form  */}
            <SideTitle title="تفاصيل المنتج" />
            <Stack
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                gap="10px"
            >
                <ImagePreview
                    setValue={setValue}
                    errorMessage={errors?.img?.message}
                    IPObjectFit="contain"
                />
                <InputField
                    type="text"
                    label="اسم المنتج"
                    registration={{ ...register("name") }}
                    errorMessage={errors?.name?.message}
                />
                <InputField
                    type="text"
                    label="وصف المنتج"
                    registration={{ ...register("description") }}
                    errorMessage={errors?.description?.message}
                    multiline={true}
                    minRows={3}
                    maxRows={6}
                />
                <InputField
                    type="number"
                    label="سعر انيس الثابت"
                    registration={{ ...register("anis_fixed_price") }}
                    errorMessage={errors?.anis_fixed_price?.message}
                />
                <Stack gap="10px">
                    <Stack direction="row" gap="10px">
                        <TextField
                            value={selectedCard.businessPrice}
                            label="سعر أنيس"
                            inputProps={{
                                readOnly: true,
                            }}
                            sx={{ flex: 1 }}
                            disabled={watch("anis_fixed_price") > 0}
                        />
                        <Controller
                            name="is_anis_global_mod"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    disabled={watch("anis_fixed_price") > 0}
                                    control={
                                        <Switch
                                            {...field}
                                            checked={watch(
                                                "is_anis_global_mod"
                                            )}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                setValue(
                                                    "is_anis_global_mod",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="استخدم معدل السعر العام"
                                />
                            )}
                        />
                    </Stack>
                    {!watch("is_anis_global_mod") ? (
                        <Stack direction="row" gap="10px">
                            <InputField
                                type="number"
                                label="معدل سعر"
                                registration={{ ...register("anis_price_mod") }}
                                errorMessage={errors?.anis_price_mod?.message}
                                styles={{ flex: 1 }}
                            />
                            <TextField
                                value={(
                                    watch("anis_price_mod") *
                                    (selectedCard.businessPrice || 0)
                                ).toFixed(2)}
                                label="السعر"
                                inputProps={{
                                    readOnly: true,
                                }}
                                sx={{ flex: 1 }}
                            />
                        </Stack>
                    ) : (
                        <></>
                    )}
                </Stack>
                <Stack
                    direction="row"
                    gap="10px"
                    sx={{ "& > *": { flex: "1" } }}
                >
                    <Stack>
                        <SelectUnit control={control} />
                        {errors?.unit ? (
                            <ErrorMessage errMessage={errors?.unit?.message} />
                        ) : (
                            <></>
                        )}
                    </Stack>
                    <Stack>
                        <SelectCategory
                            control={control}
                            watch={watch}
                            setValue={setValue}
                        />
                        {errors?.categories ? (
                            <ErrorMessage
                                errMessage={errors?.categories?.message}
                            />
                        ) : (
                            <></>
                        )}
                    </Stack>
                </Stack>

                <Controller
                    name="is_disabled"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    {...field}
                                    checked={!watch("is_disabled")}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setValue(
                                            "is_disabled",
                                            !e.target.checked
                                        )
                                    }
                                />
                            }
                            label="مفعل"
                        />
                    )}
                />
                <SubmitButton label="اضافة" disabled={submitting} />
            </Stack>
        </Stack>
    );
};

export default ConnectProductWithAnis;

const SelectedCardOptionsInfoCard = ({
    label,
    value,
}: {
    label: string;
    value: string | number;
}) => {
    return (
        <Stack
            gap="10px"
            sx={{
                minWidth: "150px",
                flex: "1",
                padding: "10px",
                boxShadow: 1,
                border: "1px solid #ddd",
                borderRadius: "5px",
                alignItems: "center",
            }}
        >
            <Typography variant="body2">{label}</Typography>
            <Typography variant="h5">{value}</Typography>
        </Stack>
    );
};

const SelectedCardOptionsInfoCardImg = ({
    label,
    img,
}: {
    label: string;
    img: string;
}) => {
    return (
        <Stack
            gap="10px"
            sx={{
                minWidth: "150px",
                flex: "1",
                padding: "10px",
                boxShadow: 1,
                border: "1px solid #ddd",
                borderRadius: "5px",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography hidden variant="body2">
                {label}
            </Typography>
            <img src={img} alt={label} width="50" loading="lazy" />
        </Stack>
    );
};
