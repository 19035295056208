import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router";

type State = {
    user: {
        userToken: "string" | undefined | "";
    };
};

const ProtectedRoutes = () => {
    const userToken = useSelector((state: State) => state.user.userToken);
    return userToken === "" || userToken === undefined ? (
        <Navigate to="/login" />
    ) : (
        <Outlet />
    );
};

export default ProtectedRoutes;
