import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
// types
type Props = {
    filterValues: {
        isConfirmed: string;
    };
    setFilterValues: Dispatch<SetStateAction<{ isConfirmed: string }>>;
};
const options = [
    {
        label: "الكل",
        value: "is_confirmed:eq:true|false",
    },
    {
        label: "موكدة",
        value: "is_confirmed:eq:true",
    },
    {
        label: "غير موكدة",
        value: "is_confirmed:eq:false",
    },
];
const IsConfirmedFilterBox = ({ filterValues, setFilterValues }: Props) => {
    const handleChange = (e: SelectChangeEvent<string>) => {
        setFilterValues({
            ...filterValues,
            isConfirmed: e.target.value,
        });
    };
    return (
        <FormControl sx={{ minWidth: "230px", flex: "1" }}>
            <InputLabel> حالة الطلب</InputLabel>
            <Select
                name="status_filter"
                value={filterValues.isConfirmed}
                label="حالة الطلب"
                onChange={handleChange}
            >
                {options.map((option) => {
                    return (
                        <MenuItem value={option.value} key={option.label}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default IsConfirmedFilterBox;
