import React, { ReactNode } from "react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
// import rtlPlugin from 'stylis-plugin-rtl';
import { RtlTheme, cacheRtl } from "../../theme/RtlTheme";

type Props = {
    children: ReactNode;
};

const CacheThemeProvider = (props: Props) => {
    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={RtlTheme}>{props.children}</ThemeProvider>
        </CacheProvider>
    );
};

export default CacheThemeProvider;
