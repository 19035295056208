// react router
import { Routes, Route } from "react-router-dom";
// components
import { AuthLayout, AdminLayout, ProtectedRoutes } from "./components";
// pages
import {
    Login,
    Home,
    Orders,
    ShowOrder,
    Products,
    AddNonDigitalProduct,
    AddDigitalProduct,
    EditDigitalProduct,
    EditNonDigitalProduct,
    AnisProducts,
    Categories,
    AddCategory,
    EditCategory,
    Cities,
    AddCity,
    EditCity,
    Areas,
    AddArea,
    EditArea,
    Users,
    EditUser,
    Notification,
    AllNotifications,
    Deliverers,
    EditDeliverer,
    AddDeliverer,
    Transactions,
    ProblemsCategories,
    CommonQuestions,
    AddQuestion,
    EditQuestion,
    Banners,
    AddBanner,
    EditBanner,
    Settings,
    ConnectProductWithAnis,
    TechnicalSettings,
    OtpHistory,
    Operators,
    AddOperator,
    EditOperator,
} from "./pages";
import RoleAccess from "./components/roleAccess/RoleAccess";
// utils
import { appRoles } from "./utils/roles/appRoles";

const App = () => {
    return (
        <Routes>
            {/* protected routes  */}
            <Route element={<ProtectedRoutes />}>
                <Route element={<AdminLayout />}>
                    <Route
                        element={<RoleAccess allowedRoles={[appRoles.admin]} />}
                    >
                        <Route path="/" element={<Home />} />
                        {/* categories  */}
                        <Route path="/categories" element={<Categories />} />
                        <Route
                            path="/add-category/:parentId"
                            element={<AddCategory />}
                        />
                        <Route
                            path="/edit-category/:categoryId"
                            element={<EditCategory />}
                        />
                        {/* cities  */}
                        <Route path="/cities" element={<Cities />} />
                        <Route path="/add-city" element={<AddCity />} />
                        <Route
                            path="/edit-city/:cityId"
                            element={<EditCity />}
                        />
                        {/* areas  */}
                        <Route path="/areas" element={<Areas />} />
                        <Route path="/add-area" element={<AddArea />} />
                        <Route
                            path="/edit-area/:areaId"
                            element={<EditArea />}
                        />
                        {/* users  */}
                        <Route path="/users" element={<Users />} />
                        <Route
                            path="/edit-user/:userId"
                            element={<EditUser />}
                        />
                        {/* notification  */}
                        <Route
                            path="/notification"
                            element={<Notification />}
                        />
                        <Route
                            path="/all-notifications"
                            element={<AllNotifications />}
                        />
                        {/* transactions  */}
                        <Route
                            path="/transactions"
                            element={<Transactions />}
                        />
                        {/* banners  */}
                        <Route path="/banners" element={<Banners />} />
                        <Route path="/add-banner" element={<AddBanner />} />
                        <Route
                            path="/edit-banner/:bannerId"
                            element={<EditBanner />}
                        />
                        {/* settings  */}
                        <Route path="/settings" element={<Settings />} />
                        <Route
                            path="/technical-settings"
                            element={<TechnicalSettings />}
                        />
                        {/* otp history  */}
                        <Route
                            path="otp-history-messages"
                            element={<OtpHistory />}
                        />
                        {/* operators  */}
                        <Route path="/operators" element={<Operators />} />
                        <Route path="/add-operator" element={<AddOperator />} />
                        <Route
                            path="/edit-operator/:operatorId"
                            element={<EditOperator />}
                        />
                    </Route>
                    <Route
                        element={
                            <RoleAccess
                                allowedRoles={[
                                    appRoles.admin,
                                    appRoles.operator,
                                ]}
                            />
                        }
                    >
                        {/* orders  */}
                        <Route path="/orders" element={<Orders />} />
                        <Route
                            path="/show-order/:orderId"
                            element={<ShowOrder />}
                        />
                        {/* products  */}
                        <Route path="/products" element={<Products />} />
                        <Route
                            path="/add-non-digital-product"
                            element={<AddNonDigitalProduct />}
                        />
                        <Route
                            path="/add-digital-product"
                            element={<AddDigitalProduct />}
                        />
                        <Route
                            path="/edit-digital-product/:productId"
                            element={<EditDigitalProduct />}
                        />
                        <Route
                            path="/edit-non-digital-product/:productId"
                            element={<EditNonDigitalProduct />}
                        />
                        {/* anis products  */}
                        <Route
                            path="/anis-products"
                            element={<AnisProducts />}
                        />
                        <Route
                            path="/connect-product-with-anis/:anisCategoryId"
                            element={<ConnectProductWithAnis />}
                        />
                    </Route>
                </Route>
            </Route>
            {/* login layout  */}
            <Route element={<AuthLayout />}>
                <Route path="/login" element={<Login />} />
            </Route>
        </Routes>
    );
};

export default App;
