import * as yup from "yup";
const minUserAge = 18;
const ageInMSecs = minUserAge * 365 * 24 * 60 * 60 * 1000;
export const editUserSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, "اسم المستخدم قصير جدا")
        .required("لا يمكن ان يكون اسم المستخدم فارغ"),
    gender: yup.string().required("ادخل نوع المستخدم"),
    birthdate: yup
        .date()
        .typeError("ادخل تاريخ ميلاد المستخدم")
        .max(
            new Date(Date.now() - ageInMSecs),
            `المستخدم يجب ان يكون اكبر من ${minUserAge} عام`
        ),
    disable: yup.boolean(),
});
