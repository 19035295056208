import React, { useState } from "react";
// react router
import { useNavigate } from "react-router";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";
// react-hook-form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Stack, FormControlLabel, Switch } from "@mui/material";
// componennts
import {
    PageTitle,
    SubmitButton,
    InputField,
    ImagePreview,
} from "../../components";
// schema
import { addBannerSchema } from "../../schemes/banner/addBannerSchema";
// types
import { ReduxUserReducerState } from "../../types/global";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// form values type
type FormValues = {
    img?: File | null;
    url: string;
    is_disabled: boolean;
};

const AddBanner = () => {
    const navigate = useNavigate();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    const [resetPreview, setResetPreview] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            url: "",
            is_disabled: false,
        },
        resolver: yupResolver(addBannerSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        reset,
        watch,
        control,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/banners`, formData)
            .then((res: AxiosResponse) => {
                successAlert("تم اضافة البانر");
                setSubmitting(false);
                reset();
                setResetPreview(true);
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة بانر" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message}
                        IPObjectFit="contain"
                        resetPreview={resetPreview}
                    />
                    <InputField
                        type="url"
                        registration={{ ...register("url") }}
                        label="الرابط"
                        errorMessage={errors?.url?.message}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="انشاء" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddBanner;
