import * as yup from "yup";

export const addAreaSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, "اسم المدينة يجب ان يكون اكبر من 3 حروف")
        .required("ادخل اسم المدينة المراد انشاؤه"),
    city: yup
        .mixed()
        .nullable()
        .test("null", "المدينة التابع لها المنطقة مطلوبة", (value) => value),
    delivery_price: yup
        .number()
        .typeError("سعر تكلفة التوصيل يجب ان يكون رقم ")
        .min(0, "يجب ان يكون سعر تكلفة التوصيل اكبر من او يساوى 0")
        .required("اختر المدينة التابع لها المنطقة"),
});
