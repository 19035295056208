import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import notifySlice from "./features/notify/notifySlice";
export const store = configureStore({
    reducer: {
        user: userSlice,
        notify: notifySlice,
    },
});

export type RootState = {
    user: {
        userToken: string;
    };
};
export default store;
