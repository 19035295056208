import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// hooks
import usePageParams from "../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, Box, IconButton, Tooltip } from "@mui/material";
// components
import { PageTitle, AnisProductCard, ThreeDotsLoader } from "../../components";
// utils roles
import { getRoleNameById } from "../../utils/roles/appRoles";
// types
import { ReduxUserReducerState } from "../../types/global";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";

const AnisProducts = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const getAnisProduct = async () => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/${userRole}/anis-items/categories`)
            .then((res: AxiosResponse) => {
                setProducts(res.data.data);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };

    useEffect(() => {
        getAnisProduct();
    }, []);
    return (
        <Stack gap="20px">
            <PageTitle title="ادراة منتجات أنيس" />

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <ThreeDotsLoader />
                ) : products.length === 0 ? (
                    <p>لا توجد عناصر لعرضها</p>
                ) : (
                    products.map((product) => {
                        const { id, name, logo, subCategories } = product;
                        return (
                            <AnisProductCard
                                key={id}
                                id={id}
                                name={name}
                                logo={logo}
                                subCategories={subCategories}
                            />
                        );
                    })
                )}
            </Box>
        </Stack>
    );
};

export default AnisProducts;
