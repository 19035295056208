import React, { SetStateAction, Dispatch } from "react";
// mui
import { Box, Pagination } from "@mui/material";

type Props = {
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>> | any;
};
const PaginationFilter = ({
    totalItems,
    itemsPerPage,
    currentPage,
    setCurrentPage,
}: Props) => {
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };
    return totalItems > itemsPerPage ? (
        <Box width="100%" display="flex" justifyContent="center">
            <Pagination
                variant="outlined"
                count={Math.ceil(totalItems / itemsPerPage)}
                page={currentPage}
                onChange={handleChange}
                showFirstButton
                showLastButton
            />
        </Box>
    ) : (
        <></>
    );
};

export default PaginationFilter;
