import * as yup from "yup";

export const changeUserBalanceSchema = yup.object().shape({
    amount: yup
        .number()
        .typeError("اقيمة الشحن يجب ان تكون رقم صحيح")
        .required("ادخل قيمة الشحن"),
    type: yup.string().required("ادخل نوع العملية"),
    payment_method: yup.mixed().required("ادخل طريقة الدفع"),
});
