import React, { FC } from "react";
// mui
import { Stack, SxProps, Typography } from "@mui/material";

type Props = {
    title: string;
    value: number;
    moreStyles?: SxProps;
};
const StatsticsCard = ({ title, value, moreStyles = {} }: Props) => {
    return (
        <Stack
            spacing="5px"
            sx={{
                flex: 1,
                width: "290px",
                minWidth: "290px",
                backgroundColor: "primary.main",
                borderRadius: "5px",
                padding: "40px 0",
                color: "white",
                textAlign: "center",
                ...moreStyles,
            }}
        >
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h3" fontWeight="500">
                {value}
            </Typography>
        </Stack>
    );
};

export default StatsticsCard;
