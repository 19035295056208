import { Outlet, Navigate } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// types
import { ReduxUserReducerState } from "../../types/global";
import { appRoles } from "../../utils/roles/appRoles";
type Props = {
    allowedRoles: number[];
};

const RoleAccess = ({ allowedRoles = [appRoles.admin] }: Props) => {
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const isAllowed = allowedRoles.some((role) =>
        userData.roles.includes(role)
    );
    const callbackUrl = userData.roles.includes(appRoles.admin)
        ? "/"
        : "/anis-products";
    return userData && isAllowed ? (
        <Outlet />
    ) : (
        <Navigate to={callbackUrl} replace={true} />
    );
};

export default RoleAccess;
