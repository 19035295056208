import { IndexType } from "typescript";

type AppRoles = {
    admin: number;
    customer: number;
    guest: number;
    operator: number;
};
export const appRoles: AppRoles = {
    admin: 2,
    customer: 3,
    guest: 4,
    operator: 5,
};

export const getRoleNameById = (id: number) => {
    let result = "";
    for (let key in appRoles) {
        if ((appRoles[key as keyof AppRoles] as number) == id)
            result = key as keyof AppRoles;
    }
    return result;
};
