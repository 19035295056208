import { Stack, Avatar, Typography, Divider } from "@mui/material";
import React from "react";
import SideTitle from "../../title/SideTitle";

const OrderItemsSection = ({ orderDetails }: any) => {
    return (
        <Stack gap="10px" width="100%" maxWidth="100%" overflow="auto">
            <SideTitle title="المنتجات خاصة الطلب" />
            <Stack
                direction="row"
                gap="30px"
                justifyContent="flex-start"
                sx={{
                    "& > *": { flex: "1", minWidth: "70px" },
                }}
            >
                <Typography variant="body2">صورة المنتج</Typography>
                <Typography variant="body2">اسم المنتج</Typography>
                <Typography variant="body2">سعر المنتج</Typography>
                <Typography variant="body2">قيمة الخصم</Typography>
                <Typography variant="body2">السعر النهائي</Typography>
                <Typography variant="body2">الكمية</Typography>
            </Stack>
            <Divider />
            {orderDetails?.items?.length ? (
                orderDetails?.items?.map((item: any, index: number) => (
                    <Stack>
                        <Stack
                            direction="row"
                            gap="30px"
                            justifyContent="flex-start"
                            alignItems="center"
                            paddingBottom={2}
                            sx={{
                                "& > *": { flex: "1", minWidth: "70px" },
                            }}
                        >
                            <Stack>
                                <Avatar src={item.img} alt={item.name} />
                            </Stack>
                            <Typography variant="body2">
                                {item.name.length > 20
                                    ? item.name.slice(0, 20)
                                    : item.name}
                            </Typography>
                            <Typography variant="body2">
                                {item.price}
                            </Typography>
                            <Typography variant="body2">
                                {item.discount_value}
                            </Typography>
                            <Typography variant="body2">
                                {item.final_price}
                            </Typography>
                            <Typography variant="body2">
                                {item.quantity}
                            </Typography>
                        </Stack>
                        <Divider />
                    </Stack>
                ))
            ) : (
                <></>
            )}
        </Stack>
    );
};

export default OrderItemsSection;
