import React, { useState } from "react";
import { useParams, useNavigate } from "react-router";
// react redux
import { useSelector } from "react-redux";
// react-hook-form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Stack, FormControlLabel, Switch } from "@mui/material";
// componennts
import { PageTitle, SubmitButton, InputField } from "../../components";
// schema
import { addOperatorSchema } from "../../schemes/operator/addOperatorSchema";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
import EnhancedAxios from "../../axios/EnhancedAxios";
// form values type
type FormValues = {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    roles: "5";
    is_disabled: boolean;
};

const AddOperator = () => {
    const navigate = useNavigate();
    const userToken = useSelector((state: any) => state.user.userToken);
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            roles: "5",
            is_disabled: false,
        },
        resolver: yupResolver(addOperatorSchema),
        mode: "all",
    });
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .post(`/admin/users`, data)
            .then((res: any) => {
                successAlert(`تم اضافة مشغل '${data.name}'`);
                reset();
                setSubmitting(false);
                navigate(-1);
            })
            .catch((error: any) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    const {
        register,
        setValue,
        reset,
        control,
        watch,
        formState: { errors },
        handleSubmit,
    } = form;
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة مشغل" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="text"
                        registration={{ ...register("name") }}
                        label="الاسم"
                        errorMessage={errors?.name?.message}
                    />
                    <InputField
                        type="email"
                        registration={{ ...register("email") }}
                        label="البريد الالكتروني"
                        errorMessage={errors?.email?.message}
                    />
                    <InputField
                        type="password"
                        registration={{ ...register("password") }}
                        label="الرقم السري"
                        errorMessage={errors?.password?.message}
                    />
                    <InputField
                        type="password"
                        registration={{ ...register("password_confirmation") }}
                        label="تآكيد الرقم السري "
                        errorMessage={errors?.password_confirmation?.message}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="انشاء" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddOperator;
