import React, { useState } from "react";
// react router
import { useParams } from "react-router";
// react redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
import { changeUserBalanceSchema } from "../../schemes/user/changeUserBalanceSchema";
// form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Switch,
    FormControlLabel,
} from "@mui/material";
// components
import {
    InputField,
    SelectPaymentMethod,
    SelectTransactionType,
    SubmitButton,
} from "../../components";
// types
import { ReduxUserReducerState } from "../../types/global";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
type Props = {
    open: boolean;
    handleClose: () => void;
};
type FormValues = {
    amount: number;
    type: string;
    payment_method: string;
    notes: string;
    is_confirmed: boolean;
    user: {
        id: string;
    };
};

const ChangeUserBalanceModal = ({ open, handleClose }: Props) => {
    const { userId } = useParams<string>();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    // states
    const [submitting, setSubmitting] = useState(false);
    const form = useForm<FormValues>({
        defaultValues: {
            amount: 0,
            type: "",
            payment_method: "",
            notes: "",
            is_confirmed: false,
            user: {
                id: userId,
            },
        },
        resolver: yupResolver(changeUserBalanceSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        reset,
        control,
        watch,
        getValues,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .post(`/admin/wallet-transactions`, {
                ...data,
                payment_method: data.payment_method.name,
            })
            .then((res: AxiosResponse) => {
                successAlert("تم تعديل رصيد الممستخدم");
                setSubmitting(false);
                reset();
                handleClose();
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiPaper-root": { minWidth: { xs: "290px", sm: "450px" } },
            }}
        >
            <DialogTitle>تغيير رصيد المستخدم</DialogTitle>
            <DialogContent>
                <Stack
                    gap="10px"
                    paddingTop="15px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="number"
                        registration={{ ...register("amount") }}
                        label="القيمة"
                        errorMessage={errors?.amount?.message}
                    />
                    <SelectPaymentMethod
                        getValues={getValues}
                        errorMessage={errors?.payment_method?.message}
                        setValue={setValue}
                        control={control}
                    />
                    <SelectTransactionType
                        control={control}
                        errorMessage={errors?.type?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("notes") }}
                        label="الملاحظات"
                        multiline={true}
                        minRows={2}
                        maxRows={5}
                        errorMessage={errors?.notes?.message}
                    />
                    <Stack direction="row">
                        <Controller
                            name="is_confirmed"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...field}
                                            checked={watch("is_confirmed")}
                                        />
                                    }
                                    label="عملية مؤكدة"
                                />
                            )}
                        />
                    </Stack>
                    <SubmitButton label="تأكيد" disabled={submitting} />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeUserBalanceModal;
