import React from "react";
import { Stack, Typography } from "@mui/material";
// components
import { PageTitle, LoginForm } from "../../components";

const Login = () => {
    return (
        <Stack spacing="50px" alignItems="center" py="50px">
            <PageTitle title="تسجيل الدخول" />
            <LoginForm />
        </Stack>
    );
};

export default Login;
