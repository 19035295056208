import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// react rouoter
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Stack, TextField } from "@mui/material";
// components
import { SubmitButton, InputField } from "../../components";
// scheme
import { editUserSchema } from "../../schemes/user/editUserSchema";
import { updateCurrentUserPasswordSchema } from "../../schemes/user/updateCurrentUserPasswordSchema";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";
type FormValues = {
    password: string;
    password_confirmation: string;
};

type Props = {
    closeModal: () => void;
};

const UpdateCurrentUserPassword = ({ closeModal }: Props) => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    // states
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    // form config
    const {
        register,
        control,
        watch,
        setValue,
        reset,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = useForm<FormValues>({
        defaultValues: {
            password: "",
            password_confirmation: "",
        },
        resolver: yupResolver(updateCurrentUserPasswordSchema),
        mode: "all",
    });

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        EnhancedAxios(null, userToken)
            .put(`/admin/users/${userData.id}`, data)
            .then((res: any) => {
                successAlert(`تم تعديل كلمة المرور`);
                setSubmitting(false);
                closeModal();
            })
            .catch((error: any) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Stack
            width="100%"
            maxWidth="700px"
            gap="10px"
            component="form"
            paddingTop={1}
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextField
                label="البريد الالكتروني"
                value={userData.email}
                inputProps={{ readOnly: true }}
            />
            <InputField
                type="password"
                registration={{ ...register("password") }}
                label="كلمة المرور"
                errorMessage={errors?.password?.message}
            />
            <InputField
                type="password"
                registration={{ ...register("password_confirmation") }}
                label="تاكيد كلمة المرور"
                errorMessage={errors?.password_confirmation?.message}
            />
            <SubmitButton label="حفظ" disabled={submitting} />
        </Stack>
    );
};

export default UpdateCurrentUserPassword;
