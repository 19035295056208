import * as yup from "yup";

export const pushNotificationSchema = yup.object().shape({
    title: yup
        .string()
        .min(3, "عنوان الاشعار قصير جدا")
        .required("عنوان الاشعار مطلوب"),
    body: yup
        .string()
        .min(10, "محتوى الاشعار قصير جدا")
        .required("محتوى الاشعار مطلوب"),
});
