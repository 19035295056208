import React, { useEffect, useState } from "react";
// router
import { useParams, useNavigate } from "react-router";
// react redux
import { useSelector } from "react-redux";
// react-hook-form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Stack, FormControlLabel, Switch } from "@mui/material";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";
// componennts
import {
    PageTitle,
    SubmitButton,
    InputField,
    SelectCity,
    ThreeDotsLoader,
} from "../../components";
// schema
import { addAreaSchema } from "../../schemes/area/addAreaSchema";
// types
import { ReduxUserReducerState } from "../../types/global";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";

// form values type
type FormValues = {
    name: string;
    city: {
        id: string;
        name: string;
    } | null;
    delivery_price: number;
    is_disabled: boolean;
};
const EditArea = () => {
    const { areaId } = useParams();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            name: "",
            city: null,
            delivery_price: 0,
            is_disabled: false,
        },
        resolver: yupResolver(addAreaSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        setSubmitting(true);
        const newData = { ...data, city: { id: data?.city?.id } };
        EnhancedAxios(null, userToken)
            .put(`/admin/areas/${areaId}`, newData)
            .then((res: AxiosResponse) => {
                successAlert(`تم تعديل المدينة `);
                setSubmitting(false);
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // get current area data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/areas/${areaId}`)
            .then((res: AxiosResponse) => {
                setLoading(false);
                reset({
                    name: res.data.name,
                    city: {
                        id: res.data.city.id,
                        name: res.data.city.name,
                    },
                    is_disabled: res.data.is_disabled,
                    delivery_price: res.data.delivery_price,
                });
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, []);
    // if page still loading
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="تعديل منطقة" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="text"
                        registration={{ ...register("name") }}
                        label="اسم المنطقة"
                        errorMessage={errors?.name?.message}
                    />
                    <SelectCity
                        getValues={getValues}
                        setValue={setValue}
                        errorMessage={errors?.city?.message}
                        control={control}
                    />
                    <InputField
                        type="number"
                        registration={{ ...register("delivery_price") }}
                        label="تكلفة التوصيل"
                        errorMessage={errors?.delivery_price?.message}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="انشاء" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditArea;
