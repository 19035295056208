import React, { useState } from "react";
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    Stack,
} from "@mui/material";
import UpdateCurrentUserPassword from "../form/UpdateCurrentUserPassword";

type Props = {};

const UpdateCurrentUserModal = (props: Props) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    return (
        <Stack
            alignItems="start"
            sx={{ width: "100%", maxWidth: "800px", mb: 2 }}
        >
            <Button
                variant="contained"
                onClick={handleOpen}
                sx={{ width: "fit-content" }}
            >
                تغيير كلمة المرور
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiPaper-root": { maxWidth: "900px" },
                    marginBottom: 2,
                }}
            >
                <DialogTitle>تغيير كلمة المرور</DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "20px auto",
                        width: { xs: "260px", md: "580px" },
                    }}
                >
                    <UpdateCurrentUserPassword closeModal={handleClose} />
                </DialogContent>
            </Dialog>
        </Stack>
    );
};

export default UpdateCurrentUserModal;
