export const englishToArabic = {
    // order status
    completed: "مكتملة",
    pending: "قيد الانتظار",
    claimed: "انتظار وصول السائق",
    cancelled: "ملغية",
    arrived: "وصل",
    // gender type
    male: "ذكر",
    female: "انثى",
    // transactions
    debit: "سحب",
    credit: "ايداع",
};
export const translateToArabic = (text) => {
    return englishToArabic[text];
};
