import { IconType } from "react-icons";
import { IoHomeOutline } from "react-icons/io5";
import {
    MdOutlineForwardToInbox,
    MdOutlineLocationCity,
    MdOutlineSettings,
} from "react-icons/md";
import { BsBox2, BsBell, BsLayers } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { TbPolygon } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { RiUserSettingsLine } from "react-icons/ri";

interface Link {
    title: string;
    path: string;
    icon?: IconType | null;
}

export const adminLinks: Link[] = [
    {
        title: "الرئيسية",
        path: "/",
        icon: IoHomeOutline,
    },
    {
        title: "ادارة الطلبات",
        path: "/orders",
        icon: MdOutlineForwardToInbox,
    },
    {
        title: "ادارة المنتجات",
        path: "/products",
        icon: BsBox2,
    },
    {
        title: "ادارة منتجات أنيس",
        path: "/anis-products",
        icon: BsBox2,
    },
    {
        title: "ادارة التصنيفات",
        path: "/categories",
        icon: BiCategoryAlt,
    },
    {
        title: "ادارة المدن",
        path: "/cities",
        icon: MdOutlineLocationCity,
    },
    {
        title: "ادارة المناطق",
        path: "/areas",
        icon: TbPolygon,
    },
    {
        title: "ادارة المستخدمين",
        path: "/users",
        icon: FiUsers,
    },
    {
        title: "ادارة المشغلين",
        path: "/operators",
        icon: RiUserSettingsLine,
    },
    {
        title: "ادارة الاشعارات",
        path: "/notification",
        icon: BsBell,
    },
    {
        title: "سجل عمليات الدفع",
        path: "/transactions",
        icon: CiMoneyCheck1,
    },
    {
        title: "ادارة الاعلانات  ",
        path: "/banners",
        icon: BsLayers,
    },
    {
        title: "الضبط",
        path: "/settings",
        icon: MdOutlineSettings,
    },
];
