import * as yup from "yup";

export const addQuestionSchema = yup.object().shape({
    question: yup.string().min(3, "السؤال قصير جدا ").required("السؤال مطلوب"),
    answer: yup
        .string()
        .min(3, "الاجابة قصيرة جدا ")
        .required(" الاجابة مطلوبة"),
    category: yup
        .mixed()
        .nullable()
        .test("check-value", "اختر التصنيف الخاص السؤال", (value) => {
            return value;
        }),
});
