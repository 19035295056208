import * as yup from "yup";

export const addOperatorSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, "اسم المشغل يجب ان يكون اكبر من 3 حروف")
        .required("ادخل اسم المشغل المراد انشاؤه"),
    email: yup
        .string()
        .email("ادحل بريد الكتروني صيحيح")
        .required("البريد الكتروني مطلوب"),
    password: yup
        .string()
        .min(8, " الرقم السري قصير جدا")
        .required("ادخل الرقم السري!"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "تأكد من تطابق الرقم السري"),
});
