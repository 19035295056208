import { useEffect, useState } from "react";
// react redux
import { useSelector } from "react-redux";
// hooks
import usePageParams from "../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, Box } from "@mui/material";
// components
import {
    PageTitle,
    SortBox,
    SearchBox,
    TableGrid,
    ThreeDotsLoader,
    OrderStatusFilterBox,
} from "../../components";
// grid cols
import { ordersCols } from "../../utils/gridCols/ordersCols";
// global state  type
import { ReduxUserReducerState } from "../../types/global";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// utils
import { getRoleNameById } from "../../utils/roles/appRoles";

const Orders = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const userData = useSelector(
        (state: ReduxUserReducerState) => state.user.userData
    );
    const userRole = getRoleNameById(userData.roles[0]);

    const { page, setPage } = usePageParams();
    const [orders, setOrders] = useState([]);
    const [sortValue, setSortValue] = useState<string>("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalItems, setTotalItems] = useState(10);
    const [loading, setLoading] = useState(true);
    const [filterValues, setFilterValues] = useState({
        status: "status:eq:completed|pending|preparing",
    });
    // get all orders
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/${userRole}/invoices?page=${page}&paginate=${itemsPerPage}&sorts=${sortValue}&q=${searchValue}&filters=${filterValues.status}`
            )
            .then((res: AxiosResponse) => {
                setOrders(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, [page, sortValue, searchValue, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="ادراة الطلبات" />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
                <OrderStatusFilterBox
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            </Box>
            {loading ? (
                <ThreeDotsLoader />
            ) : orders.length === 0 ? (
                <p className="center-text">لا يوجد مستخدمين لعرضهم</p>
            ) : (
                <TableGrid
                    rows={orders}
                    cols={ordersCols}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            )}
        </Stack>
    );
};

export default Orders;
